import 'bootstrap';
import { addMessage } from "./messages";

const getDocumentPath = () => {
  return $('input[name="document_path"]').val();
}

class DocumentUploader {
  
  constructor(elt) {
    this._$elt = $(elt);
    this._filesToUpload = [];

    this.init();
    this.initDragNDrop();
  }

  init() {
    var t = this;

    t._$elt.find('input#documentsFileInput').on('change', (e) => {
      let files = e.currentTarget.files;
      for (let file of files) {
        t.addFileForUpload(file);
      }
    });

    t._$elt.find('.documents-list').on('click', 'a[action="remove-document"]', (e) => {
      let $document = $(e.currentTarget).parents('.document-to-upload');
      t.removeDocumentForUpload($document);
    });

    t._$elt.find('[action="uploadFiles"]').on('click', (e) => {
      e.preventDefault();
      t.uploadFiles();
    })
  }

  initDragNDrop() {
    var t = this;

    $('html')
    .off('dragover.documents')
    .on('dragover.documents', (e) => {
      e.preventDefault();
      e.stopPropagation();
      $('.document-drop-area').addClass('dragover');
    })
    .off('dragleave.documents')
    .on('dragleave.documents', (e) => {
      e.preventDefault();
      e.stopPropagation();
      $('.document-drop-area').removeClass('dragover');
    })
    .off('drop.documents')
      .on('drop.documents', (e) => {
      e.preventDefault();
      e.stopPropagation();
      $('.document-drop-area').removeClass('dragover');
      t.handleFileDrop(e.originalEvent);
    });
  }

  handleFileDrop(event) {
    var t = this;
    var files = [];

    t.show();
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === 'file') {
          files.push(event.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.files.length; i++) {
        files.push(event.dataTransfer.files[i]);
      }
    }
  
    for (let file of files) {
      t.addFileForUpload(file);
    }
  }

  toggle() {
    this._$elt.modal('toggle');
  }
  show() {
    this._$elt.modal('show');
  }
  close() {
    this._$elt.modal('hide');
    //this._$elt.find('.close').click();
  }

  genFileKey(file) {
    return file.name + file.type + file.size;
  }

  addFileForUpload(file) {
    var t = this;

    let fileKey = t.genFileKey(file);
    let $documentsList = t._$elt.find('.documents-list');
    if ($documentsList.find(`[file="${fileKey}"]`).length > 0) return;
    let $file = $('<div>').addClass('col-12 document-to-upload')
      .attr('file', fileKey)
    let $content = $('<div>').addClass('row align-items-center').appendTo($file);
    //$content.append($('<span>').addClass('col-10').html(file.name));
    $content.append($('<div>').addClass('col-7').append(
      $('<span>').addClass('document').html(file.name)
    ))
    $content.append($('<div>').addClass('col-3 progress p-0').append(
      $('<div>').addClass('progress-bar').attr('role', 'progressbar').attr('aria-valuemin', '0').attr('aria-valuemax', '100').attr('aria-valuenow', '0'))
    );
    $content.append($('<a>').addClass('col-2').attr('action', 'remove-document').attr('href', '#').html('cancel'));

    // $content.append(
    //   $('<div>').addClass('widget date-widget col-3')
    //     .append('<input class="border rounded bg-white text-light p-2" type="text">')
    // );
    // $content.append(
    //   $('<div>').addClass('widget text-widget col-3')
    //     .append('<input class="border rounded bg-white text-light p-2" type="text">')
    // ); 

    $file.appendTo($documentsList);
    
    t._filesToUpload.push(file);
  }

  removeDocumentForUpload($document) {
    this._filesToUpload.splice($document.index(), 1);
    $document.remove();
  }

  uploadFiles() {
    let documentPath = getDocumentPath();
    if (!documentPath) {
      console.error('Document paht unset');
      return;
    }

    for (let file of this._filesToUpload) {
      this.uploadFile(file, documentPath);
    }
  }

  uploadFile(file, documentPath) {
    var t = this;
    console.log(file);
    // upload file trough API
    var formData = new FormData();
    let fileKey = t.genFileKey(file);
    formData.append('file', file, file.name);
    formData.append('data', JSON.stringify({
      'path': documentPath,
      'fileKey': fileKey
    }));
    $.ajax({
      type: "POST",
      url: "/api/document/upload",
      xhr: () => {
          let myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', (e) => {
                t.uploadProgress(e, fileKey);
              }, false);
          }
          return myXhr;
      },
      success: function (data) {
        setTimeout(() => { t.checkUploads() }, 200);
        t.uploadProgress({complete: true}, data.fileKey);
        addMessage(`Document ${data.file} was uplodaded.`, 'success');
        t.makeDocumentAvailable(data);
      },
      error: function (error) {
          // FIXME: handle error
          console.log(error);
      },
      async: true,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000
    });
  }

  uploadProgress(event, fileKey) {
    var percent = 0;
    var position = event.loaded || event.position;
    var total = event.total;

    if (event.complete) {
      percent = 100;
    } else if (event.lengthComputable) {
        percent = Math.ceil(position / total * 99);
    }
    this._$elt.find(`.document-to-upload[file="${fileKey}"] .progress-bar`)
      .attr('aria-valuenow', percent)
      .css('width', percent+'%');
  }

  checkUploads() {
    var t = this;
    t._$elt.find('.progress-bar[aria-valuenow="100"]').each((index, bar) => {
      let $document = $(bar).parents('.document-to-upload');
      t.removeDocumentForUpload($document);
      $document.remove();
    });
    if (t._$elt.find('.document-to-upload').length == 0) {
      t.close();
    }
  }

  makeDocumentAvailable(document) {
    var t = this;

    // add document to library widget lists
    let $library = $('.documents-library-widget');
    console.log(document);
    $library.each((index, library) => {
      let $library = $(library);
      if ($library.find(`[data-id="${document.id}"]`).length == 0)
        $library.find('.documents').append(t.genDocumentLink(document));
    });

    // add document to selector widget lists
    let $documentSelect = $('.widget.document-select-widget');
    $documentSelect.each((index, widget) => {
      // FIXME: I don't like that widget logic is coded here
      // to be really clean we should actually move onto jQuery plugins
      // so functions could be invoked from the DOM; at this stage
      // there is no way to invoke the Widget object from the DOM element
      let $widget = $(widget);
      if ($widget.find(`.dropdown-item[value="${document.id}"]`).length == 0) {
        $widget.find('.dropdown-menu').append(t.genDocumentItem(document));
        $widget.find('select').append(t.genDocumentOption(document));
      }
    });

    $(window).trigger('document.avolta.new', [document]);
  }

  genDocumentLink(document) {
    return $('<a>').addClass('document')
      .attr('href', '#')
      .attr('data-target', document.url)
      .attr('data-action', 'document')
      .attr('data-id', document.id)
      .html(document.file);
  }

  genDocumentItem(document) {
    return $('<a>').addClass('dropdown-item')
      .attr('href', '#')
      .attr('value', document.id)
      .html(document.file);
  }

  genDocumentOption(document) {
    return $('<option>')
      .attr('value', document.id)
      .attr('data-target', document.url)
      .html(document.file);
  }
}

export default $(document).ready((e) => {

  if ($('#documentUploader').length == 0) return;
  window.documentUploader = new DocumentUploader($('#documentUploader'));

  // open up document uploader
  $('a[data-toggle="modal"][data-target="#documentUploader"]').on('click', (e) => {
    window.documentUploader.toggle();
  });

  // connect label button to file input
  $('#documentUploader label[for="documentsFileInput"] button').on('click', (e) => {
    e.preventDefault();
    $('#documentUploader input[type="file"]').click();
  });
  
  // override toggle button
  // to prevent bug with modal overlay staying after modal was closed
  $('[data-toggle="custom-modal"][data-target="#documentUploader"').on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.documentUploader.show();
  });
})
