import 'bootstrap'
import { addMessage } from "./messages";

const genDocumentItem = (document) => {
  return $('<a>').addClass('dropdown-item')
    .attr('href', '#')
    .attr('value', document.id)
    .html(document.file);
}

const genDocumentOption = (document) => {
  return $('<option>')
    .attr('value', document.id)
    .attr('data-target', document.url)
    .html(document.file);
}

class DocumentViewer {
  
  constructor(elt) {
    this._$elt = $(elt);
    this.init();
  }

  init() {
    var t = this;

    // delete button
    this._$elt.find('button[data-action="delete"]').on('click', (e) => {
      t.deleteCurrentDocument();
    })

    // clean up on close
    this._$elt.on('hide.bs.modal', (e) => {
      t._$elt.removeAttr('data-id');
    });
  }

  open($document) {
    var t = this;
    let target = $document.data('target'),
    documentId = $document.data('id');

    this._$elt.attr('data-id', documentId)
    this._$elt.modal('show');
    this._$elt.find('.modal-body').empty();
    this._$elt.find('.modal-body').append(
      $('<object>').attr('data', target)
    );
  }

  close() {
    this._$elt.modal('hide');
  }

  deleteCurrentDocument() {
    var t = this;
    let documentId = t._$elt.attr('data-id');
    $.get('/api/document/delete/' + documentId, {}, ( data, status, xhr ) => {
      // close the viewer
      t.close()
      // mark associated document links as deleted by adding a class that can be used to style
      $(`a.document[data-id="${documentId}"]`).addClass('deleted');
      setTimeout(() => {
        // then remove them a moment later
        $('a.document.deleted').each((index, elt) => {
          let filename = $(elt).html();
          $(elt).remove();
          addMessage(`Document ${filename} was deleted.`, 'success');
        })
      }, 1500);
      // delete document from select widget list
      $('.widget.document-select-widget').each((index, widget) => {
        let $widget = $(widget);
        $widget.find(`.select option[value="${documentId}"]`).remove();
        $widget.find(`.dropdown-item[value="${documentId}"]`).remove();
      });
    });
  }
}

export default $(document).ready((e) => {

  if ($('#documentViewer').length == 0) return;
  window.documentViewer = new DocumentViewer('#documentViewer');

  // make documents available from library, in selector
  // (most selectors are populated via DOM though, but some are note)
  $('.documents-library-widget .documents .document').each((index, elt) => {
    let $document = $(elt);
    let document = {
      id: $document.data('id'),
      url: $document.data('target'),
      file: $document.html()
    }
    $('.widget.document-select-widget').each((index, widget) => {
      let $widget = $(widget);
      if ($widget.find(`.dropdown-item[value="${document.id}"]`).length == 0) {
        $widget.find('.dropdown-menu').append(genDocumentItem(document));
        $widget.find('select').append(genDocumentOption(document));
      }
    });  
  })
  
  // open up a document from a library widget
  $('.documents-library-widget').on('click', 'a[data-action="document"]', (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.documentViewer.open($(e.currentTarget));
  });

  // open up a document from a select widget
  $('.document-select-widget').on('click.avolta.widget', 'a[data-action="document"]', (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.documentViewer.open($(e.currentTarget));
  });
})
