var _debounce = require('lodash.debounce');
var _get = require('lodash.get');
import 'bootstrap/js/dist/dropdown';

const getDocumentPath = () => {
  return $('input[name="document_path"]').val();
}

const genDocumentPath = () => {
  $.get('/api/new_document_path', {}, (data, status, xhr) => {
    $('input[name="document_path"]').val(data.path);
  });
}

const createInvestor = (name, callback) => {
  $.get('/api/investor/create', {name}, (data, status, xhr) => {
    callback(data);
    //$('input[name="document_path"]').val(data.path);
  });
}

const getExchangeRate = (currency, date, callback) => {
  let year = String(date.getFullYear()),
  mo = String(date.getMonth()+1),
  day = String(date.getDate());
  mo = mo < 10 ? '0' + mo : mo;
  day = day < 10 ? '0' + day : day;
  let dateParameter = `${year}-${mo}-${day}`;
  //endpoint = 'https://api.ratesapi.io/' + dateParameter + '?symbols=' + currency;
  let endpoint = `https://api.exchangerate.host/convert?from=EUR&to=${currency}&date=${dateParameter}`;
  $.get(endpoint, {}, (data, status, xhr) => {
    if (callback) callback(data);
  });
}

function formatDecimal(value) {
  // format a decimal string with thousands separators and 2 decimal digits
  // any seperator , or . is used as decimal separator
  if (value == undefined) return;
  var value = String(value);
  if (value.length == 0) return;

  if (value.indexOf(' ') >= 0) {
    // support for french formatting
    value = value.replace(/ /g, '')
    value = value.replace(/,/g, '.')
  } else {
    // support for english formatting
    value = value.replace(/,/g, '')
  }
  var sp = [value];

  if (value.indexOf('.') !== -1) {
    value.replace(/,/g, '');
    var sp = value.split('.');
  } else if (value.indexOf(',') !== -1) {
    var sp = value.split(',');
  }
  var iPart = sp[0];
  var dPart = sp[1];
  if (dPart == undefined || dPart.length == 0) dPart = '00';
  else if (dPart.length == 1) dPart += '0';
  if (iPart !== undefined) {
    iPart = iPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  var newVal = iPart + '.' + dPart;
  return newVal;
}

function unformatDecimal(value) {
  return String(value).replace(/,/g, '');
}

function formatInteger(value) {
  // format an integer string with thousands separators
  if (value == undefined) return;
  var value = String(value);
  if (value.length == 0) return;
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return value;
}

function unformatInteger(value) {
  return String(value).replace(/,/g, '');
}

class Widget {
  constructor(elt, onChange) {
    this._$elt = $(elt);
    // picking up alternate versions on the field
    let name = this._$elt.find('[name]').attr('name');
    if (name) {
      let alternateName = name + '__off';

      $(`[name="${alternateName}"]`).each((i, e) => {
        this._$elt = this._$elt.add($(e).parents('.widget'));
      });
    }
    this._value = undefined;
    this._onChange = onChange;
    this.init();
  }

  /**
   * Sets input value
   */
  set value(newValue) {
  
    this._value = newValue;
    this._$elt.find('[name]').val(newValue);
  }
  /**
   * Returns input value
   */
  get value() {
    return this._value;
  }

  set onChange(onChange) {
    this._onChange = onChange;
  }
  get onChange() {
    return this._onChange;
  }
  callOnChange() {
    if (this._onChange)
      this._onChange();
  }

  /**
   * Usually returns human readable or typed value
   */
  val() {
    return this._$elt.find('[name]').val();
  }

  init() {}

  lock() {
    this._$elt.addClass('locked');
    this._$elt.find('[name]').attr('disabled', true);
  }

  unlock() {
    this._$elt.removeClass('locked');
    this._$elt.find('[name]').removeAttr('disabled');
  }

  hide() {
    this._$elt.addClass('d-none');
  }

  show() {
    this._$elt.removeClass('d-none');
  }
}

class CheckboxWidget extends Widget {

  init() {
    this.value = this._$elt.find('input[name]').is(':checked');
    
    this._$elt.find('input[name]').on('change.avolta.widget', (e) => {
      this.value = $(e.currentTarget).is(':checked');
      this.callOnChange();
    })
  }

  set value(newValue) {
    this._value = newValue;
    this._$elt.find('input[name]').prop('checked', newValue);
  }

  get value() {
    return this._value;
  }

  val() {
    return this.value;
  }

}

class SelectWidget extends Widget {

  init() {
    this.value = this._$elt.find('input[name]').attr('value');
    this._$elt.on('click.avolta.widget', '.dropdown-item', (e) => {
      let value = $(e.currentTarget).attr('value');
      this.value = value;
      this.callOnChange();
    })
  }

  set value(newValue) {
    this._value = newValue;
    this._$elt.find('input[name]').val(newValue);
    
    this._$elt.find('a.dropdown-item').removeClass('active');
    let $item = this._$elt.find(`a.dropdown-item[value="${newValue}"]`);
    $item.addClass('active');
    
    let label = $item.html();
    this._$elt.find('a.dropdown-toggle').attr('value', newValue).html(label);
  }

  get value() {
    return this._value;
  }
  
  val() {
    let htmlValue = this._$elt.find('a.dropdown-item.active').html();
    if (htmlValue && htmlValue.length > 0)
      return htmlValue;
    else
      return undefined;
  }
}

class ChoiceWidget extends Widget {

  init() {
    this.value = this._$elt.find('input[name]').attr('value');

    this._$elt.find('a.btn-choice').on('click.avolta.widget', (e) => {
      e.preventDefault();
      let $btn = $(e.currentTarget);
      this.value = $btn.attr('value');
      this.callOnChange();
    });
  }

  set value(newValue) {
    if (newValue == this._value) return;
    this._value = newValue;

    this._$elt.find('input[name]').val(newValue);

    this._$elt.find('a.btn-choice').each((index, elt) => {
      let val = $(elt).attr('value');
      if (newValue != undefined && newValue.indexOf(val) >= 0) {
        $(elt).addClass('active');
      } else {
        $(elt).removeClass('active');
      }
    });
  }

  val() {
    let htmlValue = this._$elt.find('a.active').html()
    if (htmlValue && htmlValue.length > 0)
      return htmlValue;
    else
      return undefined;
  }
}

class MultiChoiceWidget extends Widget {

  init() {
    let value = [];
    this._$elt.find('select option[selected]').each((index, elt) => {
      value.push($(elt).attr('value'));
    })
    this.value = value;

    this._$elt.find('a.btn-choice').on('click.avolta.widget', (e) => {
      let $btn = $(e.currentTarget);
      let val = $btn.attr('value');
      $btn.toggleClass('active');
      let value = this._value;
      if ($btn.hasClass('active')) {
        value.push(val);
      } else {
        let i = value.indexOf(val);
        if (i == -1)
          return;
        value.splice(i, 1);
      }
      this.value = value;
      this.callOnChange();
    });
  }

  set value(newValue) {
    this._value = newValue;
    this._$elt.find('select option').each((index, elt) => {
      let val = $(elt).attr('value');
      if (newValue.indexOf(val) >= 0) {
        $(elt).attr('selected', 'selected');
      } else {
        $(elt).removeAttr('selected');
      }
    });
    this._$elt.find('a.btn-choice').each((index, elt) => {
      let val = $(elt).attr('value');
      if (newValue.indexOf(val) >= 0) {
        $(elt).addClass('active');
      } else {
        $(elt).removeClass('active');
      }
    });
  }
}

class PercentageWidget extends Widget {
  init() {
    this.value = this._$elt.find('input[name]').attr('value');
    this._$elt.find('input[name]').on('change.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      value = (Math.round(value * 100) / 100);
      $(e.currentTarget).val(value);
      this.value =  value;
      this.callOnChange();
    })
  }

  set value(newValue) {
    if (Number.isNaN(newValue)) newValue = undefined;
    if (newValue != undefined) newValue = (Math.round(newValue * 100) / 100);
    this._value = newValue;
    this._$elt.find('input[name]').val(newValue);
  }

  get value() {
    return this._value;
  }

  val() {
    let val = parseFloat(this.value);
    if (!isNaN(val)) return val;
    return undefined;
  }
}

class DateWidget extends Widget {
  init() {
    this.rendered_separator = ' / ';
    this.editor_separator = '/';
    
    let value = this._$elt.find('input[name]').attr('value');
    this.value = this.formatValue(value, this.rendered_separator);

    this._$elt.find('input[name]').on('focus.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = this.formatValue(value, this.editor_separator);
    });
    this._$elt.find('input[name]').on('blur.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = this.formatValue(value, this.rendered_separator);
      this.callOnChange();
    });
  }

  set value(newValue) {
    this._value = newValue;
    this._$elt.find('input[name]').val(newValue);
  }
  get value() {
    return this._value;
  }

  formatValue(value, separator) {
    if (!value) return value;
    let members = value.match(/(\d{1,2})[^\d]+(\d{1,2})[^\d]+(\d{1,4})/);
    if (members) {
      let day = members[1], month = members[2], year = members[3];
      day = day.length == 2 ? day : '0' + day;
      month = month.length == 2 ? month : '0' + month;
      year = year.length == 4 ? year : '19' + year;
      return [day, month, year].join(separator);
    }
  }

  val() {
    if (!this.value) return this.value;
    let members = this.value.match(/(\d{1,2})[^\d]+(\d{1,2})[^\d]+(\d{1,4})/);
    if (members) {
      let day = members[1], month = members[2], year = members[3];
      return new Date(year=year, month=month-1, day=day);
    }
    return null;
  }
}

class MoneyWidget extends Widget {
  init() {
    this._currency = this._$elt.attr('currency');
    this._exchangeRate = undefined;
    
    let value = undefined;
    if (this._currency == 'EUR') {
      value = this._$elt.find('.converted input[name]').attr('value');
    } else {
      value = this._$elt.find('.input input[name]').attr('value');
    }
    this.value = formatDecimal(value);

    this._$elt.find('input[name]').on('focus.avolta.widget', (e) => {
      let $input = $(e.currentTarget);
      let value = $input.val();
      $input.val(unformatDecimal(value));
    });
    this._$elt.find('input[name]').on('blur.avolta.widget', (e) => {
      let $input = $(e.currentTarget);
      let value = $input.val();

      if ($input.attr('name').endsWith('_in')) {
        this.value = formatDecimal(value);
      } else {
        if (this._exchangeRate) {
          let inputValue = Math.round(parseFloat(unformatDecimal(value)) * this._exchangeRate * 100) / 100;
          if (!Number.isNaN(inputValue))
            this._$elt.find('.input input[name]').val(formatDecimal(inputValue));
        }
        $input.val(formatDecimal(value));
      }
      this.callOnChange();
    });
    // this._$elt.find('input[name]').on('focus.avolta.widget', (e) => {
    //   let value = $(e.currentTarget).val();
    //   this.value = unformatDecimal(value);
    // });
    // this._$elt.find('input[name]').on('blur.avolta.widget', (e) => {
    //   let value = $(e.currentTarget).val();
    //   this.value = formatDecimal(value);
    //   this.callOnChange();
    // });
    // this._$elt.find('input[name]').on('change.avolta.widget', (e) => {
    //   let $input = $(e.currentTarget);
    //   console.log($input.attr('name'));
    // });
  }

  set value(newValue) {
    var t = this;
    if (Number.isNaN(newValue)) newValue = undefined;
    if (typeof(newValue) == 'number') {
      newValue = Math.round(newValue * 100) / 100;
    }
    this._value = newValue;

    this._$elt.each((i, elt) => {
      let $elt = $(elt);
      let value = newValue;
      if ($elt.hasClass('locked')) {
        value = formatDecimal(value);
        $elt.find('.converted input[name]').val(formatDecimal(value));
      } else {
        $elt.find('.input input[name]').val(formatDecimal(value));
        if (t._exchangeRate) {
          let convertedValue = Math.round(parseFloat(unformatDecimal(value)) / t._exchangeRate * 100) / 100;
          if (!Number.isNaN(convertedValue))
            $elt.find('.converted input[name]').val(formatDecimal(convertedValue));
        } else {
          if (t._currency == 'EUR')
            $elt.find('.converted input[name]').val(formatDecimal(value));
        }
      }
    })
  }

  get value() {
    return this._value;
  }

  val() {
    let val = parseFloat(unformatDecimal(this.value));
    if (!isNaN(val)) return val;
    return undefined;
  }

  setCurrency(currency) {
    var t = this;
    
    let sp = currency.split(' ');
    currency = sp[1]
    let symbol = sp[0];

    t._$elt.attr('currency', currency);
    this._currency = currency;
  }

  setExchangeRate(exchangeRate) {
    var t = this;
    t._exchangeRate = exchangeRate;
    let inputValue = parseFloat(unformatDecimal(t._$elt.find('.input input[name]').val()));
    if (Number.isNaN(inputValue)) inputValue = undefined;
    let convertedValue = parseFloat(unformatDecimal(t._$elt.find('.converted input[name]').val()));
    if (Number.isNaN(convertedValue)) convertedValue = undefined;
    if (t._exchangeRate != undefined) {
      if (convertedValue != undefined && inputValue == undefined) {
        let inputValue = Math.round(convertedValue * t._exchangeRate * 100) / 100;
        t.value = inputValue;        
      } else {
        t.value = inputValue;
      }
    }
  }
}

class UnitWidget extends Widget {
  init() {
    let value = this._$elt.find('input[name]').attr('value');
    this.value = formatInteger(value);

    this._$elt.find('input[name]').on('focus.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = unformatInteger(value);
    });
    this._$elt.find('input[name]').on('blur.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = formatInteger(value);
    });
    this._$elt.find('input[name]').on('change.avolta.widget', (e) => {
      this.callOnChange();
    });
  }

  set value(newValue) {
    if (Number.isNaN(newValue)) newValue = undefined;
    if (this._$elt.hasClass('locked'))
      newValue = formatInteger(newValue);
    this._value = newValue;
    this._$elt.find('input[name]').val(newValue);
  }

  get value() {
    return this._value;
  }

  val() {
    let val = parseFloat(unformatInteger(this.value));
    if (!isNaN(val)) return val;
    return undefined;
  }
}

class CoeffWidget extends Widget {
  init() {
    let value = this._$elt.find('input[name]').attr('value');
    this.value = formatDecimal(value);

    this._$elt.find('input[name]').on('focus.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = unformatDecimal(value);
    });
    this._$elt.find('input[name]').on('blur.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = formatDecimal(value);
      this.callOnChange();
    });
  }

  set value(newValue) {
    if (Number.isNaN(newValue)) newValue = undefined;
    if (typeof(newValue) == 'number') {
      newValue = Math.round(newValue * 100) / 100;
    }
    if (this._$elt.hasClass('locked'))
      newValue = formatDecimal(newValue);
    this._value = newValue;
    this._$elt.find('input[name]').val(newValue);
  }

  get value() {
    return this._value;
  }

  val() {
    let val = parseFloat(unformatDecimal(this.value));
    if (!isNaN(val)) return val;
    return undefined;
  }
}

class NumberWidget extends Widget {
  init() {
    let value = this._$elt.find('input[name]').attr('value');
    this.value = parseInt(value);

    this._$elt.find('input[name]').on('change.avolta.widget', (e) => {
      let value = $(e.currentTarget).val();
      this.value = parseInt(value);
      this.callOnChange();
    });
  }

  set value(newValue) {
    this._value = newValue;
    if (Number.isNaN(newValue)) newValue = undefined;
    this._$elt.find('input[name]').val(newValue);
  }

  get value() {
    if (Number.isNaN(this._value))
      return undefined;
    return this._value;
  }

  val() {
    return this.value;
  }
}

class AutocompleteWidget extends Widget {
  init() {
    var t = this;

    this._endpoint = this._$elt.find('.user input').attr('src');

    let debouncedAutocomplete = _debounce((e) => this.autocomplete(e), 100);
    this._$elt.find('.user input').on('keypress.avolta.widget', debouncedAutocomplete);
    this._$elt.find('.user input').on('keydown.avolta.widget', debouncedAutocomplete);
    this._$elt.find('.user input').on('change.avolta.widget', debouncedAutocomplete);

    //this._$elt.find('.user input').on('blur.avolta.widget', (e) => {
      // FIXME: test newly focused element to decide wether bluring widget or not
      // if (!this._$list.hasClass('show')) {
      //   this.blur();
      // }
    //})

    this._$list = this._$elt.find('.autocomplete-list');
    this._$list.on('click.avolta.widget', 'a.dropdown-item', (e) => {
      t._selectingItem = true;
      let $item = $(e.currentTarget);
      let label = $item.html(), value = $item.attr('value');
      t.addItem({label, value});
      t.clearInput();
      t.focus();
      t._selectingItem = undefined;
    });

    this._$list.on('click.avolta.widget', 'a.create-item', (e) => {
      t._selectingItem = true;
      let $item = $(e.currentTarget);
      let value = $item.attr('value');
      createInvestor(value, (data) => {
        t.addItem(data);
        t.clearInput();
        t.focus();
        t._selectingItem = undefined;
      });
    });

    this._$elt.on('click.avolta.widget', '.item a', (e) => {
      t.removeItem($(e.currentTarget).parents('.item').attr('value'));
    });

    let value = [];
    this._$elt.first().find('.item').each((index, elt) => {
      let $elt = $(elt); 
      value.push({label: $elt.find('span').html(), value: $elt.attr('value')});
    });
    this.value = value;
  }

  autocomplete(evt) {
    var t = this;
    let term = $(evt.currentTarget).val();

    $.getJSON(t._endpoint, {term}, function( data, status, xhr ) {
        t.makeAutocompleteList(data);
    });
  }

  clearInput() {
    this._$elt.find('.user input').val('');
  }
  focus() {
    this._$elt.find('.user input').focus();
    this.hideAutocompleteListItem();
  }
  blur() {
    this.hideAutocompleteListItem();
  }

  makeAutocompleteList(data) {
    var t = this;
    t._$list.empty();
    if (data.results.length > 0) {
      for (let result of data.results) {
        if (t.indexOf(result) == -1)
          t._$list.append(t.makeAutocompleteListItem(result)
        );
      }
      t.showAutocompleteListItem();
    } else {
        let val = this._$elt.find('.user input').val();
        if (val.length > 0) {
          t._$list.append(t.makeCreateOption(val));
          t.showAutocompleteListItem();          
        } else {
          t.hideAutocompleteListItem();
        }
    }
  }

  makeAutocompleteListItem(item) {
    return $('<a>')
      .addClass('dropdown-item')
      .attr('href', '#')
      .attr('value', item.value)
      .html(item.label);
  }

  showAutocompleteListItem() {
    //this._$list.addClass('active');
    this._$list.dropdown('show');
  }
  hideAutocompleteListItem() {
    //this._$list.removeClass('active');
    this._$list.dropdown('hide');
  }

  makeCreateOption(name) {
    return $('<a>')
      .addClass('create-item')
      .attr('href', '#')
      .attr('value', name)
      .html(`Create new investor ${name}`);
  }

  set value(newValue) {
    var t = this;
    this._$elt.find('.item').remove();
    let $userInput = this._$elt.find('input');
    let $select = this._$elt.find('select');
    $select.empty();
    for (let item of newValue) {
      $userInput.before(t.makeItem(item));
      $select.append($('<option selected>').attr('value', item.value));
    }
    this._value = newValue;
  }

  get value() {
    return this._value;
  }

  addItem(item) {
    var t = this;
    let widgetValue = t.value;
    widgetValue.push(item);
    t.value = widgetValue;
  }

  removeItem(value) {
    var t = this;
    let i = t.indexOf({value});
    if (i >= 0) {
      let widgetValue = t.value;
      widgetValue.splice(i, 1);
      t.value = widgetValue;
    }
  }

  indexOf(item) {
    var t = this;
    //for (let existingItem of t._value) {
    for (let i = 0; i < t._value.length; ++i) {
      if (t._value[i].value == item.value)
        return i;
    }
    return -1;
  }

  makeItem(item) {
    return $('<div>').addClass('item')
      .attr('value', item.value)
      .append($('<span>').html(item.label))
      .append($('<a href="#" aria-hidden="true">&times;</a>'))
  }
}

class AddressWidget extends Widget {
  init() {
    var t = this;
    t.connectGMapsComponent();
  }

  connectGMapsComponent() {
    var t = this;
    if (!window.addressAutocomplete) {
       setTimeout(() => { t.connectGMapsComponent(); }, 500);
       return;
    }
    window.addressAutocomplete.addListener('place_changed', () => {
      t.addressChanged();
    });
  }

  addressChanged() {
    var t = this;
    let place = window.addressAutocomplete.getPlace();
    let source = t._$elt.find('input').attr('source');
    $(`[for="${source}"]`).each((index, elt) => {
      let $elt = $(elt);
      if (elt.tagName == 'IFRAME') {
        var src = $elt.attr('src');
        if (place.formatted_address) {
          src = src.replace(/&q=.*/, '&q=' + place.formatted_address);
          $elt.attr('src', src);
          $elt.parents('.map').removeClass('disabled');
        } else {
          src = src.replace(/&q=.*/, '&q=');
          $elt.attr('src', src);
          $elt.parents('.map').addClass('disabled');
        }
      } else {
        let component = $elt.attr('component');
        if (component) {
          let value = _get(place, component);
          $elt.val(value);
        }
      }
    });
  }
}

class DocumentSelectWidget extends Widget {

  init() {
    var t = this;

    let value = [];
    t._$elt.find('select option[selected]').each((index, elt) => {
      value.push($(elt).attr('value'));
    })
    t.value = value;

    // custom dropdown
    // t._$elt.find('.document-dropdown-toggle').on('click', (e) => {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   if (t._$elt.find('.dropdown-menu').hasClass('show')) {
    //     //$(window).off('click.avolta.widget.document-select');
    //     // FIXME: dropdown is not hidden here:
    //     t._$elt.find('.document-dropdown-toggle').dropdown('hide');
    //   } else {
    //     t._$elt.find('.document-dropdown-toggle').dropdown('toggle');
    //     $(window).off('click.avolta.widget.document-select')
    //     .on('click.avolta.widget.document-select', (e) => {
    //       t._$elt.find('.document-dropdown-toggle').dropdown('hide');
    //       $(window).off('click.avolta.widget.document-select');
    //     });
    //   }
    // });

    // select dropdown item
    t._$elt.find('.dropdown-menu').off('click.avolta.widget')
    .on('click.avolta.widget', '.dropdown-item', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let $item = $(e.currentTarget);
      $item.toggleClass('active');

      let value = [];
      t._$elt.find('.dropdown-item.active').each((index, elt) => {
        value.push($(elt).attr('value'));
      });
      t.value = value;
      t._$elt.find('.document-dropdown-toggle').dropdown('update');
    })
  }

  set value(newValue) {
    var t = this;
    
    t._$elt.find('.dropdown-item').each((index, elt) => {
      let $elt = $(elt);
      let value = $elt.attr('value');
      if (newValue.indexOf(value) >= 0) {
        $elt.addClass('active');
      } else {
        $elt.removeClass('active');
      }
    });
    
    t._$elt.find('select option').each((index, elt) => {
      let $elt = $(elt);
      let value = $elt.attr('value');
      if (newValue.indexOf(value) >= 0) {
        $elt.attr('selected', 'selected');
      } else {
        $elt.removeAttr('selected');
      }
    });
    
    let $list = t._$elt.find('.documents-list');
    $list.empty().html('&nbsp;');
    for (let value of newValue) {
      let $document = t._$elt.find(`select option[value="${value}"]`)
      $list.append($('<a>').addClass('document')
        .attr('data-action', 'document')
        .attr('data-target', $document.attr('data-target'))
        .attr('data-id', value)
        .attr('href', '#')
        .html($document.html())
      );
    }
    if (newValue.length == 0) {
      t._$elt.find('.dropdown-toggle').removeClass('p-0').addClass('p-2');
    } else {
      t._$elt.find('.dropdown-toggle').removeClass('p-2').addClass('p-0');
    }
    $('.document-select-widget a[data-action="document"]').on('click.avolta.widget', (e) => {
      e.preventDefault();
      e.stopPropagation();
      window.documentViewer.open($(e.currentTarget));
    });

    t._value = newValue;
  }

  get value() {
    return this._value;
  }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const bindWidgets = ($elt) => {
  ($elt ? $elt.find('.select-widget') : $('.select-widget')).each((index, elt) => {
    new SelectWidget(elt);
  });
  ($elt ? $elt.find('.percentage-widget') : $('.percentage-widget')).each((index, elt) => {
    new PercentageWidget(elt);
  });
  ($elt ? $elt.find('.choice-widget') : $('.choice-widget')).each((index, elt) => {
    new ChoiceWidget(elt);
  });
  ($elt ? $elt.find('.multichoice-widget') : $('.multichoice-widget')).each((index, elt) => {
    new MultiChoiceWidget(elt);
  });
  ($elt ? $elt.find('.autocomplete-widget') : $('.autocomplete-widget')).each((index, elt) => {
    new AutocompleteWidget(elt);
  });
  ($elt ? $elt.find('.address-widget') : $('.address-widget')).each((index, elt) => {
    new AddressWidget(elt);
  });
  ($elt ? $elt.find('.date-widget') : $('.date-widget')).each((index, elt) => {
    new DateWidget(elt);
  });
  ($elt ? $elt.find('.money-widget') : $('.money-widget')).each((index, elt) => {
    new MoneyWidget(elt);
  });
  ($elt ? $elt.find('.document-select-widget') : $('.document-select-widget')).each((index, elt) => {
    new DocumentSelectWidget(elt);
  });
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const makeWidget = (elt, changeCallback) => {
  let $elt = $(elt);
  if (!$elt.hasClass('widget')) $elt = $elt.parents('.widget');
  
  let widget = undefined;
  if ($elt.hasClass('select-widget'))
    widget = new SelectWidget($elt, changeCallback);
  else if ($elt.hasClass('percentage-widget'))
    widget = new PercentageWidget($elt, changeCallback);
  else if ($elt.hasClass('choice-widget'))
    widget = new ChoiceWidget($elt, changeCallback);
  else if ($elt.hasClass('multichoice-widget'))
    widget = new MultiChoiceWidget($elt, changeCallback);
  else if ($elt.hasClass('autocomplete-widget'))
    widget = new AutocompleteWidget($elt, changeCallback);
  else if ($elt.hasClass('address-widget'))
    widget = new AddressWidget($elt, changeCallback);
  else if ($elt.hasClass('date-widget'))
    widget = new DateWidget($elt, changeCallback);
  else if ($elt.hasClass('money-widget'))
    widget = new MoneyWidget($elt, changeCallback);
  else if ($elt.hasClass('checkbox-widget'))
    widget = new CheckboxWidget($elt, changeCallback);
  else if ($elt.hasClass('unit-widget'))
    widget = new UnitWidget($elt, changeCallback);
  else if ($elt.hasClass('coeff-widget'))
    widget = new CoeffWidget($elt, changeCallback);
  else if ($elt.hasClass('number-widget'))
    widget = new NumberWidget($elt, changeCallback);
  else if ($elt.hasClass('document-select-widget'))
    widget = new DocumentSelectWidget($elt, changeCallback);
  else
    widget = new Widget($elt, changeCallback);
  
  return widget;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

class RevenueForm {
  constructor($elt, options) {
    this._$elt = $($elt);

    if (options) {
      if (options.formSet) {
        this._formSet = options.formSet;
      }
      if (options.year !== undefined) {
        this._$elt.find('input[name$="-year"]').val(options.year);
        this._$elt.find('input[name$="-year"]').attr('value', options.year);
        this._$elt.find('input[name$="-year"]').attr('initial-value', options.year);
      }
      if (options.index) {
        this._$elt.find('input[name]').each((index, input) => {
          let $input = $(input);
          let name = $input.attr('name'), id = $input.attr('id');
          let matches = name ? name.match(/form-\d*-(\w*)/) : null;
          if (matches) {
            name = 'form-' + options.index + '-' + matches[1];
            $input.attr('name', name)
          }
          matches = id ? id.match(/id_form-\d*-(\w*)/) : null;
          if (matches) {
            id = 'id_form-' + options.index + '-' + matches[1];
            $input.attr('id', id);
          }
        });
      }

      // hacky fix for extra form of the set with no initial data provided
      let startupId = this._$elt.find('input[name$="-startup"]').val();
      if (!startupId) {
        startupId = $('.startup-form input[name="startup"]').val();
        this._$elt.find('input[name$="-startup"]').val(startupId);
      }
    }

    var t = this;
    // bind delete button
    t._$elt.find('a[action="delete-form"]').on('click.avolta.widget', (e) => {
      t.confirmDeletion();
    });
    t._$elt.find('a[action="confirm-delete-form"]').on('click.avolta.widget', (e) => {
      t.delete();
    });
    t._$elt.find('a[action="cancel-delete-form"]').on('click.avolta.widget', (e) => {
      t.cancelDeletion();
    });

    t._widgets = {
      year: makeWidget(t._$elt.find('input[name$="-year"]')),
      revenue: makeWidget(t._$elt.find('input[name$="-revenue"]')),
      source: makeWidget(t._$elt.find('input[name$="-source"]')),
      ebitda: makeWidget(t._$elt.find('input[name$="-ebitda"]')),
      ebitdaSource: makeWidget(t._$elt.find('input[name$="-ebitda_source"]')),
      comment: makeWidget(t._$elt.find('input[name$="-comment"]')),
      exchangeRate: makeWidget(t._$elt.find('input[name$="-exchange_rate"]')),
      documents: makeWidget(t._$elt.find('select[name$="-documents"]'))
    }

    t._widgets.year.onChange = () => {
      t.setExchangeRate();
    }
  }

  get $elt() {
    return this._$elt;
  }

  confirmDeletion() {
    var t = this;

    t._$elt.find('.delete-control').toggleClass('d-flex d-none')
    t._$elt.find('.delete-confirm').toggleClass('d-flex d-none')
  }

  cancelDeletion() {
    var t = this;

    t._$elt.find('.delete-control').toggleClass('d-flex d-none')
    t._$elt.find('.delete-confirm').toggleClass('d-flex d-none')
  }

  delete() {
    var t = this;

    let initialYearValue = t._$elt.find('input[name$="-year"]').attr('initial-value');
    if (!initialYearValue) {
      // if this is a newly added form, order the formset to delete it
      t._formSet.deleteForm(this);
    } else {
      // otherwise, simply mark it for deletion
      t._$elt.find('input[name$="-year"]').val(initialYearValue); // reset year value
      t._$elt.find('input[name$="-deleted"]').val(1); // set deleted field
      t._$elt.hide(); // hide
    }
  }

  setCurrency(currency) {
    var t = this;

    t._currency = currency;
    for (let widget of [
      'revenue',
      'ebitda']) {
      t._widgets[widget].setCurrency(currency);
    }
    t.setExchangeRate();
  }

  setExchangeRate() {
    var t = this;

    t._widgets.exchangeRate.value = undefined;
    if (t._currency) {
      let currency = t._currency.split(' ')[1];
      if (currency == 'EUR') {
        t._$elt.find('.exchange-rate-field').addClass('disabled');
        for (let widget of [
          'revenue',
          'ebitda']) {
          t._widgets[widget].setExchangeRate(undefined);
        }
      } else {
        t._$elt.find('.exchange-rate-field').removeClass('disabled');
        let year = t._widgets.year.val();
        if (year && t._endOfFiscalYear != undefined) {
          let date = new Date(year, t._endOfFiscalYear, 1);
          getExchangeRate(currency, date, (data) => {
            let exchangeRate = data.result;
            t._widgets.exchangeRate.value = exchangeRate;
            for (let widget of [
              'revenue',
              'ebitda']) {
              t._widgets[widget].setExchangeRate(exchangeRate);
            }
          })
        } else {
          for (let widget of [
            'revenue',
            'ebitda']) {
            t._widgets[widget].setExchangeRate(undefined);
          }
        }
      }
    }
  }

  setEndOfFiscalYear(eoy) {
    this._endOfFiscalYear = eoy;
    this.setExchangeRate();
  }

  val() {
    var t = this;

    let source = t._widgets.source.val(),
        ebitdaSource = t._widgets.ebitdaSource.val(),
        trustScore = undefined, ebitdaTrustScore = undefined;
    if (source) {
      let sp = source.split(' ');
      source = sp[0];
      trustScore = parseFloat(sp[1].slice(1));
    }
    if (ebitdaSource) {
      let sp = ebitdaSource.split(' ');
      ebitdaSource = sp[0];
      ebitdaTrustScore = parseFloat(sp[1].slice(1));
    }

    return {
      year: t._widgets.year.val(),
      revenue: t._widgets.revenue.val(),
      source: source,
      trustScore: trustScore,
      ebitda: t._widgets.ebitda.val(),
      ebitdaSource: ebitdaSource,
      ebitdaTrustScore: ebitdaTrustScore,
      comment: t._widgets.comment.val()
    }
  }
}

class RevenueFormSet {

  constructor($elt) {
    this._$elt = $($elt);
    this._meta = {
      $totalForms: this._$elt.find('input[name="form-TOTAL_FORMS"]'),
      $initialForms: this._$elt.find('input[name="form-INITIAL_FORMS"]'),
      $minNumForms: this._$elt.find('input[name="form-MIN_NUM_FORMS"]'),
      $maxNumForms: this._$elt.find('input[name="form-MAX_NUM_FORMS"]')
    }
    
    this._forms = [];
    this._$elt.find('.revenue-form').each((index, elt) => {
      this._forms.push(new RevenueForm(elt, {formSet: this}));
    });

    this._$elt.find('a[action="add-form"]').on('click.avolta.widget', (e) => {
      e.preventDefault();
      this.addForm();
    })
  }

  addForm() {
    var t = this;
    
    let lastForm = t._forms[t._forms.length - 1];

    let $clonedForm = lastForm.$elt.clone(false);
    //bindWidgets($clonedForm);
    $clonedForm.find('input[name$="-revenue"]').val(null);
    $clonedForm.find('input[name$="-revenue"]').attr('value', null);
    $clonedForm.find('input[name$="-ebitda"]').val(null);
    $clonedForm.find('input[name$="-ebitda"]').attr('value', null);
    $clonedForm.find('input[name$="-source"]').val(null);
    $clonedForm.find('input[name$="-source"]').attr('value', null);
    $clonedForm.find('input[name$="-ebitda_source"]').val(null);
    $clonedForm.find('input[name$="-ebitda_source"]').attr('value', null);
    $clonedForm.find('input[name$="-comment"]').val(null);
    $clonedForm.find('input[name$="-comment"]').attr('value', null);
    $clonedForm.find('select[name$="-documents"] option').removeAttr('selected');

    let newForm = new RevenueForm($clonedForm,
      {formSet: this, year: null, index: t._forms.length});
    newForm.$elt.insertAfter(lastForm.$elt);
    newForm.setCurrency(t._currency);
    newForm.setEndOfFiscalYear(t._endOfFiscalYear);
    t._forms.push(newForm);
    t._meta.$totalForms.val(parseInt(t._meta.$totalForms.val()) + 1);
  }

  deleteForm(form) {
    var t = this;
    let formIndex = form._$elt.index() - 2;
    t._forms.splice(formIndex, 1);
    form._$elt.remove();
    t._meta.$totalForms.val(parseInt(t._meta.$totalForms.val()) - 1);
  }

  val() {
    var t = this;
    return t._forms.map((f) => f.val());
  }

  setCurrency(currency) {
    var t = this;
    t._currency = currency;
    for (let form of t._forms) {
      form.setCurrency(currency);
    }
  }

  setEndOfFiscalYear(eoy) {
    var t = this;
    t._endOfFiscalYear = eoy;
    for (let form of t._forms) {
      form.setEndOfFiscalYear(eoy);
    }
  }

}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

class StartupForm {
  
  constructor($elt) {
    this._$elt = $elt;
    
    this.initWidgets();

    // init revenue form set
    this.revenueFormSet = new RevenueFormSet(this._$elt.find('.revenue-form-set')[0]);

    this.setCurrency();
    this.setEndOfFiscalYear();
    window.startup_form = this;
  }

  initWidgets() {
    var t = this;
    this._widgets = {
      name: makeWidget(this._$elt.find('input[name="name"]')),
      nationality: makeWidget(this._$elt.find('input[name="nationality"]')),
      identifier_type: makeWidget(this._$elt.find('input[name="identifier_type"]')),
      identifier: makeWidget(this._$elt.find('input[name="identifier"]')),
      legal_name: makeWidget(this._$elt.find('input[name="legal_name"]')),
      website: makeWidget(this._$elt.find('input[name="website"]')),
      creation_date: makeWidget(this._$elt.find('input[name="creation_date"]')),
      endOfFiscalyear: makeWidget(this._$elt.find('[name="end_of_fiscal_year"]')),
      currency: makeWidget(this._$elt.find('input[name="initial_currency"]')),
      description: makeWidget(this._$elt.find('[name="description"]')),
      business_model: makeWidget(this._$elt.find('[name="business_model"]')),
      commission: makeWidget(this._$elt.find('[name="commission"]')),
      focus: makeWidget(this._$elt.find('[name="focus"]')),
      tags: makeWidget(this._$elt.find('[name="tags"]')),
      address: makeWidget(this._$elt.find('[name="address"]'))
    }

    t._widgets.currency.onChange = () => {
      t.setCurrency();
    }
    t._widgets.endOfFiscalyear.onChange = () => {
      t.setEndOfFiscalYear();
    }
  }

  setCurrency() {
    var t = this;
    let currency = t._widgets.currency.val();
    t.revenueFormSet.setCurrency(currency);
  }
  
  setEndOfFiscalYear() {
    var t = this;
    let eoy = parseInt(t._widgets.endOfFiscalyear.value);
    if (Number.isNaN(eoy)) eoy = undefined;
    t.revenueFormSet.setEndOfFiscalYear(eoy);
  }
}

class TransactionForm {

  constructor($elt) {
    this._$elt = $elt;
    this.initWidgets();
    window.transaction_form = this;
  }

  initWidgets() {
    var t = this;
    
    let userFields = {
      series: makeWidget(t._$elt.find('input[name="series"]')),
      dateInPress: makeWidget(t._$elt.find('input[name="date_in_press"]')),
      investors: makeWidget(t._$elt.find('select[name="investors"]')),
      advisors: makeWidget(t._$elt.find('select[name="advisors"]')),
      currency: makeWidget(t._$elt.find('input[name="currency"]')), 
      documents: makeWidget(t._$elt.find('select[name="documents"]')),

      // deal fields
      newMoney: makeWidget(t._$elt.find('input[name="new_money"]')),
      actualDate: makeWidget(t._$elt.find('input[name="actual_date"]')),
      isActualDateConfirmed: makeWidget(t._$elt.find('input[name="is_actual_date_confirmed"]')),
      totalSharesPreop: makeWidget(t._$elt.find('input[name="total_shares_preop"]')),
      newShares: makeWidget(t._$elt.find('input[name="new_shares"]')),
      pricePerShare: makeWidget(t._$elt.find('input[name="price_share"]')),
      totalOther: makeWidget(t._$elt.find('input[name="total_other"]')),
      otherSharesBsa: makeWidget(t._$elt.find('input[name="other_shares_bsa"]')),
      otherSharesDebt: makeWidget(t._$elt.find('input[name="other_shares_debt"]')),

      // exit fields
      isExit: makeWidget(t._$elt.find('input[name="is_exit"]')),
      entrepriseValue: makeWidget(t._$elt.find('input[name="entreprise_value"]')),
      equityValue: makeWidget(t._$elt.find('input[name="equity_value"]')),
      netDebt: makeWidget(t._$elt.find('input[name="net_debt"]')),
      stake: makeWidget(t._$elt.find('input[name="stake"]')),
      stakeValue: makeWidget(t._$elt.find('input[name="stake_value"]')),
      // acquirers: makeWidget(t._$elt.find('select[name="acquirers__off"]')),
    }

    for (let field in userFields) {
      userFields[field].onChange = () => { t.computeValues(); }
    }

    let computedFields = {
      exchangeRate: makeWidget(t._$elt.find('input[name="exchange_rate"]')),

      evPreMoney: makeWidget(t._$elt.find('input[name="ev_pre_money"]')),
      evPostMoneyFD: makeWidget(t._$elt.find('input[name="ev_post_money_fd"]')),
      evPostMoneyND: makeWidget(t._$elt.find('input[name="ev_post_money_nd"]')),
      actualNm: makeWidget(t._$elt.find('input[name="actual_nm"]')),
      totalFunding: makeWidget(t._$elt.find('input[name="total_funding"]')),
      totalSharesND: makeWidget(t._$elt.find('input[name="total_shares_nd"]')),
      totalSharesPostop: makeWidget(t._$elt.find('input[name="total_shares_postop"]')),
      dilution: makeWidget(t._$elt.find('input[name="dilution"]')),
      
      revenueInfo: makeWidget(t._$elt.find('input[name="revenue_info"]')),
      growth: makeWidget(t._$elt.find('input[name="annual_growth"]')),
      ttm: makeWidget(t._$elt.find('input[name="ttm"]')),
      ttmGmv: makeWidget(t._$elt.find('input[name="ttm_gmv"]')),
      vtmInfo: makeWidget(t._$elt.find('input[name="vtm_info"]')),
      revenueTrustScore: makeWidget(t._$elt.find('input[name="revenue_trust_score"]')),
      eqvTtmRevenuePreMoney: makeWidget(t._$elt.find('input[name="eqv_ttm_pre_money"]')),

      ebitdaInfo: makeWidget(t._$elt.find('input[name="ebitda_info"]')),
      ttmEbitda: makeWidget(t._$elt.find('input[name="ttm_ebitda"]')),
      ebitdaSales: makeWidget(t._$elt.find('input[name="ebitda_sales"]')),
      evTtmEbitda: makeWidget(t._$elt.find('input[name="ev_ttm_ebitda"]')),
    }

    t._widgets = Object.assign({}, userFields, computedFields);
  }

  setCurrency(currency) {
    var t = this;
    for (let widget of [
      'pricePerShare',
      'newMoney',
      'totalOther',
      'entrepriseValue',
      'equityValue',
      'netDebt',
      'stakeValue',
      'evPreMoney']) {
      t._widgets[widget].setCurrency(currency);
    }

    currency = currency.split(' ')[1];
    if (currency == 'EUR') {
      t._widgets.exchangeRate.value = undefined;
      t._$elt.find('.exchange-rate-field').addClass('disabled');
    } else {
      t._$elt.find('.exchange-rate-field').removeClass('disabled');
    }
  }

  setExchangeRate(exchangeRate) {
    var t = this;
    for (let widget of [
      'pricePerShare',
      'newMoney',
      'totalOther',
      'entrepriseValue',
      'equityValue',
      'netDebt',
      'stakeValue']) {
      t._widgets[widget].setExchangeRate(exchangeRate);
    }
  }

  computeValues() {
    var t = this;

    // set transaction type
    let series = t._widgets.series.val(),
        isMajorityInvestment = undefined;
    if (series) {
      isMajorityInvestment = (series == 'M&amp;A' || series == 'Exit' || series == 'ACQUISITION' || series == 'MERGER' || series == 'BUYOUT' || series == 'IPO' || series == 'Corporate Development');
    }
    this.setTransactionMajority(isMajorityInvestment);
    
    // set currency
    let currency = t._widgets.currency.val();
    if (currency) {
      t.setCurrency(currency);
      currency = currency.split(' ')[1];
    }

    // compute processed values
    let dateInPress = t._widgets.dateInPress.val(),
        actualDate = t._widgets.actualDate.val(),
        date = actualDate ? actualDate : dateInPress,
        dealYear = date ? date.getFullYear() : undefined,
        dealMonth = date ? date.getMonth() : undefined;
    
    if (date && currency != 'EUR') {
      getExchangeRate(currency, date, (data) => {
        let exchangeRate = data.result;
        t._widgets.exchangeRate.value = exchangeRate;
        t.setExchangeRate(exchangeRate);
      });
    }

    let years = t.startupForm.revenueFormSet.val();
    let dealYearData = undefined, dealPreviousYearData = undefined;
    for (let year of years) {
      if (year.year == dealYear) {
        dealYearData = year;
      } else if (year.year == dealYear-1) {
        dealPreviousYearData = year;
      }
    }

    let revenueInfo = false,
    ebitdaInfo = false,
    growth = undefined,
    ttm = undefined,
    ttmEbitda = undefined,
    ttmGmv = undefined,
    vtmInfo = undefined,
    revenueTrustScore = undefined,
    ebitdaTrustScore = undefined,
    eqvTtmRevenuePreMoney = undefined,
    ebitdaOnSales = undefined,
    evTtmEbitda = undefined;

    if (dealYear != undefined && dealYearData && dealPreviousYearData) {
      if (dealYearData.revenue != undefined && dealPreviousYearData.revenue != undefined)
        revenueInfo = true;
      if (dealYearData.ebitda != undefined && dealPreviousYearData.ebitda != undefined)
        ebitdaInfo = true;
      
      growth = (dealYearData.revenue / dealPreviousYearData.revenue - 1) * 100;

      let eoy = this.startupForm._widgets.endOfFiscalyear.value;
      if (eoy != undefined) {
        eoy = parseInt(eoy);
        if (dealMonth == eoy) {
          if (dealYearData.revenue) ttm = dealYearData.revenue;
          if (dealPreviousYearData.revenue) ttmEbitda = dealYearData.ebitda;
        } else {
          var monthDealYear = eoy >= dealMonth ? eoy - dealMonth : dealMonth - eoy;
          var monthDealPreviousYear = 12 - monthDealYear;
          if (dealYearData.revenue && dealPreviousYearData.revenue) {
            ttm = (monthDealYear * dealPreviousYearData.revenue + monthDealPreviousYear * dealYearData.revenue) / 12;
          }
          if (dealYearData.ebitda && dealPreviousYearData.ebitda) {
            ttmEbitda = (monthDealYear * dealPreviousYearData.ebitda + monthDealPreviousYear * dealYearData.ebitda) / 12;
          }
        }
      }

      let commission = this.startupForm._widgets.commission.val();
      if (commission) {
        ttmGmv = ttm / commission;
      }

      if (dealYearData.trustScore && dealPreviousYearData.trustScore) {
        revenueTrustScore = (dealYearData.trustScore + dealPreviousYearData.trustScore) + 2;
      }

      if (revenueInfo && ebitdaInfo) {
        ebitdaOnSales = ttmEbitda / ttm;
      }
    }

    let evPreMoney = undefined;
    let evPostMoneyFD = undefined;
    let evPostMoneyND = undefined;
    let actualNm = undefined;
    let totalFunding = undefined;
    let totalSharesND = undefined;
    let totalSharesPostop = undefined;
    let dilution = undefined;

    if (!isMajorityInvestment) {
      let newShares = t._widgets.newShares.val();
      let pricePerShare = t._widgets.pricePerShare.val();
      let totalSharesPreop = t._widgets.totalSharesPreop.val();
      let totalOther = t._widgets.totalOther.val();
      let otherSharesBsa = t._widgets.otherSharesBsa.val();
      let otherSharesDebt = t._widgets.otherSharesDebt.val();

      if (newShares && pricePerShare) {
        actualNm = newShares * pricePerShare;
  
        totalFunding = actualNm + (totalOther != undefined ? totalOther : 0);
        totalSharesND = (totalSharesPreop || 0) + newShares;
        totalSharesPostop = totalSharesND
          + (otherSharesBsa != undefined ? otherSharesBsa : 0)
          + (otherSharesDebt != undefined ? otherSharesDebt : 0);
        
        evPostMoneyND = totalSharesND * pricePerShare;
        evPostMoneyFD = totalSharesPostop * pricePerShare;
        dilution = newShares / totalSharesPostop * 100;
      }

      if (totalSharesPreop && pricePerShare) {
        evPreMoney = totalSharesPreop * pricePerShare;
      }

      vtmInfo = '';
      if (!evPreMoney || !ttm)
        vtmInfo = "No Multiple";
      else if (evPreMoney && ttm < 90000) // FIXME: use site configuration value
        vtmInfo = "Pre revenue";
      else
        vtmInfo = "Revenue Gen.";

      if (vtmInfo == 'Revenue Gen.') {
        eqvTtmRevenuePreMoney = evPreMoney / ttm;
      }

    } else {
      let isExit = t._widgets.isExit.val();
      let entrepriseValue = t._widgets.entrepriseValue.val();
      evPreMoney = entrepriseValue;
      let netDebt = t._widgets.netDebt.val();
      let equityValue = t._widgets.equityValue.val();
      let stake = t._widgets.stake.val();
      let stakeValue = t._widgets.stakeValue.val();

      if (isExit) {
        t._widgets.stake.value = 100;
        t._widgets.stake.lock();
        t._widgets.stakeValue.hide();
      } else {
        t._widgets.stake.unlock();
        t._widgets.stakeValue.show();
      }

      if (ttmEbitda) {
        evTtmEbitda = entrepriseValue / ttmEbitda;
      }
    }

    // update deal computed values
    t._widgets.actualNm.value = actualNm;
    t._widgets.totalFunding.value = totalFunding;
    t._widgets.totalSharesND.value = totalSharesND;
    t._widgets.totalSharesPostop.value = totalSharesPostop;
    t._widgets.dilution.value = dilution;
    t._widgets.evPostMoneyND.value = evPostMoneyND;
    t._widgets.evPostMoneyFD.value = evPostMoneyFD;
    t._widgets.evPreMoney.value = evPreMoney;

    // update revenue computed values
    t._widgets.revenueInfo.value = revenueInfo;
    t._widgets.growth.value = growth;
    t._widgets.ttm.value = ttm;
    t._widgets.ttmGmv.value = ttmGmv;
    t._widgets.vtmInfo.value = vtmInfo;
    t._widgets.revenueTrustScore.value = revenueTrustScore;
    t._widgets.eqvTtmRevenuePreMoney.value = eqvTtmRevenuePreMoney

    // update ebitda computed values
    t._widgets.ebitdaInfo.value = ebitdaInfo;
    t._widgets.ttmEbitda.value = ttmEbitda;
    t._widgets.ebitdaSales.value = ebitdaOnSales;
    t._widgets.evTtmEbitda.value = evTtmEbitda;

  }

  setTransactionMajority(status) {
    var t = this;
    if (status == undefined) return;
    if (status) {
      t._$elt.find('[transaction-type="deal"]').each((i, section) => {
        let $section = $(section);
        $section.addClass('d-none');
        $section.find('input[name]').each((j, input) => {
          let $input = $(input);
          $input.attr('name', $input.attr('name').replace('__off', '') + '__off');
        });
        $section.find('input[id]').each((j, input) => {
          let $input = $(input);
          $input.attr('id', $input.attr('id').replace('__off', '') + '__off');
        });
      });
      t._$elt.find('[transaction-type="exit"]').each((i, section) => {
        let $section = $(section);
        $section.removeClass('d-none');
        $section.find('input[name]').each((j, input) => {
          let $input = $(input);
          $input.attr('name', $input.attr('name').replace('__off', ''));
        });
        $section.find('input[id]').each((j, input) => {
          let $input = $(input);
          $input.attr('id', $input.attr('id').replace('__off', ''));
        });
      });
    } else {
      t._$elt.find('[transaction-type="exit"]').each((i, section) => {
        let $section = $(section);
        $section.addClass('d-none');
        $section.find('input[name]').each((j, input) => {
          let $input = $(input);
          $input.attr('name', $input.attr('name').replace('__off', '') + '__off');
        });
        $section.find('input[id]').each((j, input) => {
          let $input = $(input);
          $input.attr('id', $input.attr('id').replace('__off', '') + '__off');
        });
      });
      t._$elt.find('[transaction-type="deal"]').each((i, section) => {
        let $section = $(section);
        $section.removeClass('d-none');
        $section.find('input[name]').each((j, input) => {
          let $input = $(input);
          $input.attr('name', $input.attr('name').replace('__off', ''));
        });
        $section.find('input[id]').each((j, input) => {
          let $input = $(input);
          $input.attr('id', $input.attr('id').replace('__off', ''));
        });
      });
    }
  }

}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export default $(document).ready((e) => {

  if ($('.page.edit-startup').length == 0) return;

  // fix Bootstrap dropdowns not-auto binded anymore with dropdown library import
  $('.dropdown-toggle').dropdown();

  // init forms
  let startupForm = new StartupForm($('.startup-form'));
  let transactionForm = new TransactionForm($('.transaction-form'));

  startupForm.transactionForm = transactionForm;
  transactionForm.startupForm = startupForm;

  transactionForm.computeValues();

  // check on document path and generate if unset
  const documentPath = getDocumentPath();
  if (!documentPath || documentPath == undefined)
    genDocumentPath();

  // init submit button
  $('a.submit').click((e) => {
    $('form').submit();
  })

  $('a.archive-startup').click((e) => {
    e.preventDefault();
    if (confirm("Do you want to discard all modifications and archive this startup? This action can be undone.")) {
      document.location = $(e.currentTarget).attr('href');
    }
  });

  $('a.unarchive-startup').click((e) => {
    e.preventDefault();
    if (confirm("Do you want to discard all modifications and unarchive this startup? This action can be undone.")) {
      document.location = $(e.currentTarget).attr('href');
    }
  });

  $('a.archive-deal').click((e) => {
    e.preventDefault();
    if (confirm("Do you want to discard all modifications and archive this transaction? This action can be undone.")) {
      document.location = $(e.currentTarget).attr('href');
    }
  });

  $('a.unarchive-deal').click((e) => {
    e.preventDefault();
    if (confirm("Do you want to discard modifications and unarchive this transaction? This action can be undone.")) {
      document.location = $(e.currentTarget).attr('href');
    }
  });

});