const moment = require('moment');

class Criteria {

  constructor() {    
    this.$tag = $();
    this._label = undefined;
    this._value = undefined;
    this._display = {}
  }

  get label() {
    return this._label;
  }
  set label(label) {
    this._label = label;
  }

  set value(value) {
    this._value = value;
  }
  get value() {
    return this._value;
  }

  set display(display) {
    this._display = display;
    this.buildTag();
  }
  get display() {
    return this._display;
  }

  buildTag() {
    this.$tag = $('<div>').addClass('criteria')
      .attr('data-label', this.label)
      .attr('data-value', this.value)
      .append($('<a>').addClass('remove').append($('<span aria-hidden="true">&times;</span>')))
      .append($('<div>').addClass('content')
        .append($('<span>').addClass('label').html(this.display.label))
        .append($('<span>').addClass('value').html(this.display.value))
      );
  }

}

class ChoiceCriteria extends Criteria {

  constructor(label, value, displayLabel, displayValue) {
    super();
    this.type = 'choice';
    this.label = label;
    this.value = value;
    this.display = {
      label: displayLabel ? displayLabel : label,
      value: displayValue ? displayValue : value
    };
  }
}

class DateCriteria extends Criteria {

  constructor(label, displayLabel, fromDateLabel, fromDateValue, toDateLabel, toDateValue) {
    super();
    this.type = 'date';
    this.label = label;
    this.fromDate = fromDateValue;
    this.toDate = toDateValue;

    this.display = {
      label: displayLabel ? displayLabel : label,
      value: (fromDateLabel ?  'from ' + fromDateLabel : '')
      + (toDateLabel ?  ' to ' + toDateLabel : '')
    };
  }
}

class LocationCriteria extends Criteria {

  constructor(label, geometry, displayLabel, displayValue) {
    super();
    this.type = 'location';
    this.label = label;
    this.value = displayValue;
    this.address = displayValue;
    this.searchValue = displayValue + '>>' + geometry;

    this.display = {
      label: displayLabel ? displayLabel : label,
      value: displayValue
    };
  }
}

class CriteriaSelector {

  constructor(form, $element) {
    this._form = form;
    this._$elt = $element;
    this._type = $element.data('type');
    this._name = $element.data('name');

    this.init();
  }

  get name() {
    return this._name;
  }
  
  get type() {
    return this._type;
  }

  init() {}

  selectValue() {}
  unselectValue() {}
  toggleValue() {}
}

const makeCriteriaSelector = (form, $element) => {
  switch ($element.data('type')) {
    case 'choice':
      return new CriteriaChoiceSelector(form, $element);
    case 'autocomplete':
      return new CriteriaAutocompleteSelector(form, $element);
    case 'date':
      return new CriteriaDateSelector(form, $element);
    case 'location':
      return new CriteriaLocationSelector(form, $element);
    return null;
  }
}

const makeCriteriaFromTag = ($element) => {
  let label = $element.data('label');
  let value = $element.data('value');
  let displayLabel = $element.find('.label').html();
  let displayValue = $element.find('.value').html();
  let criteria = undefined;
  switch(label) {
      case 'tag':
      case 'business_model':
      case 'focus':
      case 'stage':
      case 'revenue':
      case 'new_money':
      case 'total_raised':
        criteria = new ChoiceCriteria(label, value, displayLabel, displayValue);
        break;
    case 'location':
        criteria = new LocationCriteria(
            label, JSON.stringify(value), displayLabel, displayValue
        );
        break;
    case 'date':
        criteria = new DateCriteria(
            label, displayLabel,
            value.split(':')[0], value.split(':')[0],
            value.split(':')[1], value.split(':')[1],
        );
  }
  if (criteria) {
    criteria.index = $element.index();
    criteria.$tag = $element;
    return criteria;
  }
}

class CriteriaChoiceSelector extends CriteriaSelector {

  init() {
    var t = this;
    
    t._$elt.find('.choices').on('click', 'a', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let $choice = $(e.currentTarget);
      //$choice.toggleClass('active');
      
      let value = $choice.data('value'),
          label = t._$elt.data('name'),
          displayValue = $choice.html(),
          displayLabel = t._$elt.data('label');
      let criteria = new ChoiceCriteria(label, value, displayLabel, displayValue);
      if ($choice.hasClass('active')) {
        t._form.removeCriteria(criteria);
      } else {
        t._form.addCriteria(criteria);
      }
    });
  }

  selectValue(value) {
    var t = this;
    let $choice = t._$elt.find(`.choices [data-value="${value}"]`);
    $choice.addClass('active');
  }

  unselectValue(value) {
    var t = this;
    let $choice = t._$elt.find(`.choices [data-value="${value}"]`);
    $choice.removeClass('active');
  }

  toggleValue(value) {
    var t = this;
    let $choice = t._$elt.find(`.choices [data-value="${value}"]`);
    $choice.toggleClass('active');
  }
}

class CriteriaAutocompleteSelector extends CriteriaSelector {

  init() {
    var t = this;
    
    t._endpoint = t._$elt.data('target');
    t._$list = t._$elt.find('.results-list');
    t._$input = t._$elt.find('input');
    t._values = [];
    if (!t._endpoint) return;

    t._$elt.find('.initial .value').each((index, elt) => {
      t._values.push($(elt).attr('value'));
    });
    
    t._$input.on('keyup.avolta.autocomplete', (e) => {
      let term = $(e.currentTarget).val();
      $.getJSON(t._endpoint, {term}, function( data, status, xhr ) {
        t.makeAutocompleteList(data);
      });
    });

    t._$list.on('click.avolta.autocomplete', 'a.dropdown-item', (e) => {
      e.stopPropagation();
      let $item = $(e.currentTarget);
      let value = $item.data('value'),
          label = t._$elt.data('name'),
          displayValue = $item.html(),
          displayLabel = t._$elt.data('label');
      let criteria = new ChoiceCriteria(label, value, displayLabel, displayValue);
      if (t._values.indexOf(value) >= 0) {
        t._values.splice(t._values.indexOf(value), 1);
        t._form.removeCriteria(criteria);
      } else {
        t._values.push(value);
        t._form.addCriteria(criteria);
      }
      t._$list.empty();
      t._$input.val(undefined).focus();
    })
  }

  selectValue(value) {
    var t = this;
  }

  unselectValue(value) {
    var t = this;
  }

  toggleValue(value) {
    var t = this;
  }

  // for autocomplete type selectors
  makeAutocompleteList(data) {
    var t = this;
    t._$list.empty();
    if (data.results.length > 0) {
      for (let result of data.results) {
        t._$list.append(t.makeAutocompleteListItem(result));
      }
    }
  }

  // for autocomplete type selectors
  makeAutocompleteListItem(item) {
    var t = this;
    return $('<a>')
      .addClass('dropdown-item')
      .attr('href', '#')
      .attr('data-value', item.label)
      .html(item.label);
  }
}

class CriteriaDateSelector extends CriteriaSelector {

  init() {
    var t = this;
    t._$inputFrom = t._$elt.find('input[data="from"]');
    t._$inputTo = t._$elt.find('input[data="to"]');
    t._$elt.find('input').on('keyup.avolta.widget', (e) => {
      t.buildCriteria();
    })
  }

  unselectValue() {
    var t = this;
    t._$inputFrom.val(undefined);
    t._$inputTo.val(undefined);
  }

  buildCriteria() {
    var t = this;
    let fromValue = t._$inputFrom.val(), toValue = t._$inputTo.val();
    let dates = {
      from: {
        raw: fromValue
      },
      to: {
        raw: toValue
      }
    };
    for (let key in dates) {
      if (dates[key].raw) {
        let members = dates[key].raw.match(/(\d{1,4})/g);
        let yearIndex, monthIndex, dayIndex, year, month, day;
        if (members) {
          switch (members.length) {
            case 1:
              yearIndex = 0;
              break;
            case 2:
              yearIndex = 1;
              monthIndex = 0;
              break;
            case 3:
              yearIndex = 2;
              monthIndex = 1;
              dayIndex = 0;
              break;
          }
          if (yearIndex != undefined) {
            year = members[yearIndex];
            if (year.length == 2)
              year = '19'+year;
            else if (year.length != 4)
              year = undefined;
          }
          if (monthIndex != undefined) {
            if (members[monthIndex].length <= 2)
              month = members[monthIndex];
          }
          if (dayIndex != undefined) {
            if (members[dayIndex].length <= 2)
              day = members[dayIndex];
          }
          let value = undefined, label = undefined;
          if (year) {
            if (month != undefined) {
              if (day != undefined) {
                value = moment(new Date(year, parseInt(month)-1, day));
                label = `${day}/${month}/${year}`;
              } else {
                value = moment(new Date(year, parseInt(month)-1));
                label = `${month}/${year}`;
              }
            } else {
              value = moment(new Date(year, 0));
              label = `${year}`;
            }
          }
          if (key == 'to') {
            if (day != undefined) {
              value = moment(value).add(1, 'day').subtract(1, 'second');
            }
            else if (month != undefined) {
              value = moment(value).add(1, 'month').subtract(1, 'second');
            } else {
              value = moment(value).add(1, 'year').subtract(1, 'second');
            }
          }
          dates[key].label = label;
          dates[key].value = value ? value.format('DD/MM/YYYY') : undefined;
        }
      }
    }
    let label = t._$elt.data('name'), displayLabel = t._$elt.data('label');
    if (dates.from.value || dates.to.value) {
      let criteria = new DateCriteria(
        label, displayLabel,
        dates['from'].label, dates['from'].value,
        dates['to'].label, dates['to'].value);  
      t._form.replaceCriteria(criteria);
    } else if (dates.from.raw.length == 0 && dates.to.raw.length == 0) {
      t._form.removeCriteria(new DateCriteria(label));
    }
  }
}

class CriteriaLocationSelector extends CriteriaSelector {
  init() {
    var t = this;
    t._$input = t._$elt.find('input');
    t._locations = [];
    t.connectGMapsComponent();  

    // $('.criteria-input .criteria[data-label="location"]').each((index, elt) => {
    //   let $criteria = $(elt);
    //   let geometry = $criteria.data('value'),
    //       location = $criteria.find('.value').html();
    //   t._locations.push(location);
    //   $criteria.data('value', location);
    //   $criteria.attr('data-value', location);
    // });
  }

  connectGMapsComponent() {
    var t = this;
    if (!window.addressAutocomplete) {
       setTimeout(() => { t.connectGMapsComponent() }, 500);
       return;
    }
    window.addressAutocomplete.addListener('place_changed', () => {
      t.locationSelected();
    });
  }

  locationSelected() {
    var t = this;
    let place = window.addressAutocomplete.getPlace();

    let label = t._$elt.data('name'), displayLabel = t._$elt.data('label');
    let location = place.formatted_address;
    let criteria = new LocationCriteria(
      label, JSON.stringify(place.geometry),
      displayLabel, location);
    if (t._locations.indexOf(location) >= 0) {
      t._locations.splice(t._locations.indexOf(location), 1);
      t._form.removeCriteria(criteria);
    } else {
      t._locations.push(location);
      t._form.addCriteria(criteria);
    }
    t._$input.val(undefined).focus();
  }

  unselectValue(value) {
    var t = this;
    if (t._locations.indexOf(value) >= 0) {
      t._locations.splice(t._locations.indexOf(value), 1);
    }
  }

}

////////////////////////////////////////////////////////////////

class SearchForm {
  
  constructor($elt) {
    var t = this;
    t._$form = $elt;
    t._criteria = [];
    t._tables = [];
    t._selectors = [];

    t.initCriteriaSelectors();
    t.initTables();
    t.initSavedSearch();

    t._$form.find('a.search').click((e) => {
      if (!$(e.currentTarget).hasClass('disabled'))
        t.makeSearch();
    });

    t._$form.find('a.set-columns').click((e) => {
      t._$form.find('.columns-selection').toggleClass('active');
      if (t._$form.find('.columns-selection').hasClass('active')) {
        $(e.currentTarget).addClass('expanded');
      } else {
        $(e.currentTarget).removeClass('expanded');
      }
    });
  }

  /// criteria selection

  initCriteriaSelectors() {
    var t = this;

    t._selectors = [];
    t._$form.find('.criteria-label').each((index, elt) => {
      t._selectors.push(makeCriteriaSelector(t, $(elt)));
    });

    // criteria interactions
    t._$form.find('.criteria-label').on('click.avolta.form', (e) => {
      t.toggleCriteriaSelector($(e.currentTarget));
      e.stopPropagation();
    });
    t._$form.find('.criteria-label').on('click.avolta.form', '.criteria-selector', (e) => {
      e.stopPropagation();
    });

    $('.criteria-input').on('click.avolta.form', '.criteria a.remove', (e) => {
      let $criteria = $(e.currentTarget).parents('.criteria');
      let label = $criteria.data('label'), value = $criteria.data('value');
      let criteria = new ChoiceCriteria(label, value);
      t.removeCriteria(criteria);
    })
  }

  toggleCriteriaSelector($criteriaLabel) {
    var t = this;

    // toggle off all criteria if none is provided
    if ($criteriaLabel === undefined || $criteriaLabel.length == 0) {
      t._$form.find('.criteria-label.active').removeClass('active');
      $(window).off('click.form_search');
      return;
    }

    $criteriaLabel.siblings().removeClass('active');
    $criteriaLabel.toggleClass('active');

    let isCriteriaActive = $criteriaLabel.hasClass('active');
    if (isCriteriaActive) {
      // bind click on window to close criteria
      setTimeout(() => {
        $(window).off('click.form_search').on('click.form_search', (e) => {
          t.toggleCriteriaSelector();
        });
      }, 0);

      // adjust position of the selector
      let $selector = $criteriaLabel.find('.criteria-selector');
      $selector.find('input').first().focus();
      let maxWidth = parseInt($selector.parents(".criteria-block").width());
      $selector.css('max-width', maxWidth);

      let overflow = ($selector.offset().left + $selector.width())
        - ($('.criteria-block').offset().left + $('.criteria-block').width());
      console.log(overflow)
      if (overflow > 0) {
        overflow = parseInt(overflow) + 2; // border shift =2px
      }
      $selector.css('transform', `translateX(-${overflow}px)`)
    } else {
      $(window).off('click.form_search');
    }
  }

  addCriteria(criteria) {
    var t = this;

    // add criteria to current state
    t._criteria.push(criteria);
    criteria.index = t._criteria.length - 1;
    // render critera in input
    t._$form.find('.criteria-input').append(criteria.$tag);
    // enable criteria from its selector
    let selector = t._selectors.find((s) => s.name == criteria.label);
    selector.selectValue(criteria.value);
    // enable search button
    t._$form.find('a.search').removeClass('disabled');
    $('#savedSearches .new-saved-search .btn-save-search').removeClass('disabled');
  }

  findCriteria(criteria) {
    var t = this;
    for (let i in t._criteria) {
      if (t._criteria[i].label == criteria.label && t._criteria[i].value == criteria.value)
        return t._criteria[i];
    }
    return null;
  }

  removeCriteria(criteria) {
    var t = this;

    let foundCriteria = t.findCriteria(criteria);
    if (foundCriteria) {
      // remove criteria from current state
      t._criteria.splice(foundCriteria.index, 1);
      // reorder other criteria
      t._criteria.filter((c) => c.index >= foundCriteria.index).map((c) => --c.index);
      // remove criteria tag from input
      foundCriteria.$tag.remove();
      // disable criteria from its selector
      let selector = t._selectors.find((s) => s.name == criteria.label);
      selector.unselectValue(criteria.value);
    }
    
    if (t._criteria.length == 0)
      t._$form.find('a.search').addClass('disabled');
      $('#savedSearches .new-saved-search .btn-save-search').addClass('disabled');
  }

  replaceCriteria(criteria) {
    var t = this;
    let existingCriteriaIndex = undefined;
    for (let i in t._criteria) {
      if (t._criteria[i].label == criteria.label) {
        existingCriteriaIndex = i;
        break;
      }
    }
    if (existingCriteriaIndex != undefined) {
      t._criteria[existingCriteriaIndex].$tag.after(criteria.$tag);
      t._criteria[existingCriteriaIndex].$tag.remove();
      t._criteria[existingCriteriaIndex] = criteria;
    } else {
      t.addCriteria(criteria);
    }
  }

  /// tables setup

  initTables() {
    var t = this;
    
    $('.results-block .table').each((index, elt) => {
      let $table = $(elt);
      let table = window.search_tables[$table.data('target')];
      t._tables.push(table);
    })

    $('.result-counts-block a[data-target]').on('click', (e) => {
      e.preventDefault();
      let $e = $(e.currentTarget).parents('.button');
      $e.siblings().removeClass('active');
      $e.addClass('active');
      
      $('.results-block > div').removeClass('active');
      let index = $e.index();
      $('.results-block > div').eq(index).addClass('active');
      t._tables[index].resizeTable();

      $('.export-controls a').removeClass('active');
      $('.export-controls a').eq(index).addClass('active');

      $('.columns-selection .table-columns-selection').removeClass('active');
      $('.columns-selection .table-columns-selection').eq(index).addClass('active');
    })
  }

  /// saved search

  initSavedSearch() {
    var t = this;

    t._$form.find('.criteria-input .criteria').each((index, elt) => {
      const $criteria = $(elt);
      const criteria = makeCriteriaFromTag($criteria);
      t._criteria.push(criteria);

      if (criteria.label == 'location') {
          // this is super ugly to process here
          let geometry = criteria.$tag.data('value'),
              location = criteria.$tag.find('.value').html();
          t._selectors.find((s) => s.name == 'location')._locations.push(location);
          criteria.$tag.data('value', location);
          criteria.$tag.attr('data-value', location);
      }

      let selector = t._selectors.find((s) => s.name == criteria.label);
      switch (selector.type) {
        case 'choice':
          selector.selectValue(criteria.value);
          break;
      
        default:
          break;
      }
    });

    if (t._criteria.length > 0) {
      t._$form.find('a.search').removeClass('disabled');
      $('#savedSearches .new-saved-search .btn-save-search').removeClass('disabled');
    }
  }

  /// search

  searchParameters() {
    var t = this;

    let searchParameters = {};
    for (let criteria of t._criteria) {
      if (criteria.type == 'choice') {
        if (!searchParameters[criteria.label]) searchParameters[criteria.label] = [];
        searchParameters[criteria.label].push(criteria.value);
      } else if (criteria.type == 'date') {
        if (criteria.fromDate) searchParameters['date_min'] = criteria.fromDate;
        if (criteria.toDate) searchParameters['date_max'] = criteria.toDate;
      } else if (criteria.type == 'location') {
        if (!searchParameters[criteria.label]) searchParameters[criteria.label] = [];
        searchParameters[criteria.label].push(criteria.searchValue);
      }
    }
    for (let param in searchParameters) {
      if (Array.isArray(searchParameters[param]))
        searchParameters[param] = searchParameters[param].join('|');
    }
    return searchParameters;
  }

  makeSearch() {
    var t = this;
    let searchParameters = t.searchParameters();

    for (let table of t._tables) {
      table.parameters = searchParameters;
      setImmediate(() => {
        table.refresh();
        $('.export-controls a').removeClass('disabled');
      });
    }
  }

}

export default $(document).ready((e) => {

  setImmediate( () => {
    window.search = new SearchForm($('.criteria-block'));
  })
});