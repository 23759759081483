require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/themes/base/all.css");

export default $(document).ready((e) => {


  var $autocomplete = $('header .searchbar input');
  if ($autocomplete.length <= 0) return;

  window.cache = {};
  $autocomplete.autocomplete({
    minLength: 2,
    source: function( request, response ) {
      var term = request.term;
      if (term in cache) {
        response(cache[term] );
        return;
      }

      $.getJSON("/ajax/searchbar", request, function( data, status, xhr ) {
        cache[term] = data['results'];
        if (cache[term].length == 0) {
          cache[term].push({empty: true})
        }
        response(data['results']);
      });
    },
    menuFocus: function( event, ui ) {
      return false;
    },
    focus: function( event, ui ) {
      return false;
    },
    select: function( event, ui ) {
      event.preventDefault();
      event.stopPropagation();
      window.document.location = ui.item.url;
    },
  });
  $autocomplete.autocomplete("instance")._renderItem = function( ul, item ) {
    if (item.empty) {
      return $( "<li>" ).addClass('search-bar-noresults')
      .append($('<div>')
        .append($('<span>').html('No results'))
      )
      .appendTo(ul);
    }

    return $( "<li>" ).addClass('search-bar-results')
      .append($('<div>')
        .append($('<span>').addClass('name').html(item.name))
        .append($('<span>').addClass('type').attr('value', item.type).html(item.type))
        .append($('<span>').addClass('tags').html(item.tags))
      )
      .appendTo(ul);
    };
  $autocomplete.autocomplete('instance')._renderMenu = function( ul, items ) {
    var that = this;
    $.each( items, function( index, item ) {
      that._renderItemData( ul, item );
    });

    $(ul).addClass('searchbar-autocomplete');
    // works but produces an unpleasant blink
    // setTimeout(() => {
    //   $(ul).css('width', 'auto');
    // }, 0);
  };

})
