import {Table} from './table';

export default $(document).ready((e) => {

  // recent transactions table on home page
  window.recentTransactionsTable = new Table('.page.home #recent', {
    endpoint: 'api/recent-transactions',
    rowLink: '{{url}}',
    collapseOnScroll: ['.metrics'],
    columns: [{
        label: 'Startup',
        class: 'name',
        value: '{{startup.name}}',
        display: '{{link:startup}}',
        size: 150,
        style: 'font-weight-bold'
      },{
        label: 'Date',
        class: 'date',
        value: '{{date:date}}',
        display: '{{date:d M YYYY:date}}',
        size: 150
      },{
        label: 'Stage',
        class: 'stage',
        value: '{{stage}}',
        display: '{{stage}}',
        size: 100
      },{
        label: 'Investor / Acquirers',
        class: 'investors',
        value: '{{list:investors}}',
        display: '{{link-list:investors}}',
        size: 'extend'
      },{
        label: 'Location',
        class: 'location',
        value: '{{location}}',
        display: '{{location}}',
        size: 100
      },{
        label: 'Business Model',
        class: 'bm',
        value: '{{business_model}}',
        display: '{{business_model}}',
        size: 160
      },{
        label: 'New Money',
        class: 'nm',
        value: '{{new_money}}',
        display: '{{money:0:new_money}}',
        size: 120
      },{
        label: 'Tags',
        class: 'tags autocrop',
        value: '{{list:tags}}',
        display: '{{token-list:tags}}',
        size: 'extend'
      }
    ],
    sortable: false
  });

  // transactions table on investor page
  new Table('#transactions', {
    rowLink: '{{url}}',
    collapseOnScroll: ['.blocks'],
    defaultSort: '-date_in_press',
    columns: [{
        label: 'Startup',
        class: 'name',
        value: '{{startup.name}}',
        display: '{{link:startup}}',
        size: 150,
        sort: 'startup__name',
      },{
        label: 'Date',
        class: 'date',
        value: '{{date:date}}',
        display: '{{date:d M YYYY:date}}',
        size: 150,
        sort: 'date_in_press'
      },{
        label: 'Stage',
        class: 'stage',
        value: '{{stage}}',
        display: '{{stage}}',
        size: 100
      },{
        label: 'Investor / Acquirers',
        class: 'investors',
        value: '{{list:investors}}',
        display: '{{link-list:investors}}',
        size: 'extend'
      },{
        label: 'Business Model',
        class: 'bm',
        value: '{{business_model}}',
        display: '{{business_model}}',
        size: 160
      },{
        label: 'New Money',
        class: 'nm',
        value: '{{new_money}}',
        display: '{{money:0:new_money}}',
        size: 120,
        sort: 'new_money'
      }
    ],
    sortable: false
  });

  // advanced search tables
  window.search_tables = {};
  window.search_tables.transactions = new Table('#searchTransactionsTable', {
    endpoint: '/api/advanced-search/transactions',
    parameters: null,
    method: 'POST',
    collapseOnScroll: ['.criteria-block'],
    htmlCount: '#transactionsCount',
    htmlLoader: '#transactionsLoader',
    rowLink: '{{url}}',
    emptyContent: 'No results yet, make a search first.',
    noResultsContent: 'No results for this search',
    columns: [{
        label: 'Startup',
        class: 'startup',
        value: '{{startup}}',
        display: '{{startup}}',
        size: 300,
        sort: 'startup__name'
      },{
        label: 'Stage',
        class: 'stage',
        value: '{{stage}}',
        display: '{{stage}}',
        size: 100
      },{
        label: 'Date in Press',
        class: 'date',
        value: '{{date_in_press}}',
        display: '{{date:d M YYYY:date_in_press}}',
        size: 200,
        sort: 'date_in_press'
      },{
        label: 'Investors / Acquirers',
        class: 'investors',
        value: '{{list:investors}}',
        display: '{{link-list:investors}}',
        size: 400
      },{
        label: 'New Money in Press',
        class: 'new-money',
        value: '{{new_money_in_press}}',
        display: '{{money:0:new_money_in_press}}',
        size: 200,
        sort: 'new_money',
        exportType: 'number'
      },{
        label: 'TTM Sales',
        class: 'ttm',
        value: '{{ttm}}',
        display: '{{money:0:ttm}}',
        size: 200,
        sort: 'ttm',
        exportType: 'number'
      },{
        label: 'TTM Ebitda',
        class: 'ttm',
        value: '{{ttm_ebitda}}',
        display: '{{money:0:ttm_ebitda}}',
        size: 200,
        sort: 'ttm_ebitda',
        exportType: 'number'
      },{
        label: 'Annual Growth',
        class: 'annual-growth',
        value: '{{annual_growth}}',
        display: '{{percentage:2:annual_growth}}',
        size: 200,
        sortable: 'annual_growth',
        exportType: 'number'
      },{
        label: 'EV / TTM Sales',
        value: '{{ev_ttm_sales}}',
        display: '{{coefficient:0:ev_ttm_sales}}',
        size: 200,
        sort: 'eqv_ttm_revenue_pre_money',
        exportType: 'number'
      },{
        label: 'EV / TTM Ebitda',
        value: '{{ev_ttm_ebitda}}',
        display: '{{coefficient:0:ev_ttm_ebitda}}',
        size: 200,
        sort: 'ev_ttm_ebitda',
        exportType: 'number'
      }
    ],
    sortable: false
  });

  window.search_tables.startups = new Table('#searchStartupsTable', {
    endpoint: '/api/advanced-search/startups',
    parameters: null,
    method: 'POST',
    collapseOnScroll: ['.criteria-block'],
    htmlCount: '#startupsCount',
    htmlLoader: '#startupsLoader',
    rowLink: '{{url}}',
    emptyContent: 'No results yet, make a search first.',
    noResultsContent: 'No results for this search',
    columns: [{
        label: 'Name',
        class: 'name',
        value: '{{name}}',
        display: '{{name}}',
        size: 300,
        sort: 'name'
      },{
        label: 'Creation',
        class: 'creation',
        value: '{{creation_date}}',
        display: '{{date:d M YYYY:creation_date}}',
        size: 300,
        sort: 'creation_date'
      },{
        label: 'Business Model',
        class: 'business-model',
        value: '{{business_model}}',
        display: '{{business_model}}',
        size: 300,
        sort: 'business_model__fr'
      },{
        label: 'Last TTM',
        class: 'last-ttm',
        value: '{{last_ttm}}',
        display: '{{money:0:last_ttm}}',
        size: 300,
        sort: 'last_ttm',
        exportType: 'number'
      },{
        label: 'Total raised',
        class: 'raised',
        value: '{{total_raised}}',
        display: '{{money:0:total_raised}}',
        size: 300,
        sort: 'total_raised',
        exportType: 'number'
      },{
        label: 'Identifier',
        class: 'identifier',
        value: '{{identifier}}',
        display: '{{identifier}}',
        size: 300,
        sort: 'identifier'
      },{
        label: 'Legal name',
        value: '{{legal_name}}',
        display: '{{legal_name}}',
        size: 300,
        sort: 'legal_name'
      },{
        label: 'Tags',
        class: 'tags autocrop',
        value: '{{list:tags}}',
        display: '{{token-list:tags}}',
        size: 300
      },{
        label: 'Focus',
        class: 'tags autocrop',
        value: '{{list:focus}}',
        display: '{{token-list:focus}}',
        size: 300
      }
    ],
    sortable: false
  });

  window.search_tables.investors = new Table('#searchInvestorsTable', {
    endpoint: '/api/advanced-search/investors',
    parameters: null,
    method: 'POST',
    collapseOnScroll: ['.criteria-block'],
    htmlCount: '#investorsCount',
    htmlLoader: '#investorsLoader',
    rowLink: '{{url}}',
    emptyContent: 'No results yet, make a search first.',
    noResultsContent: 'No results for this search',
    defaultSort: '-nb_investments',
    columns: [{
        label: 'Name',
        class: 'name',
        value: '{{name}}',
        display: '{{name}}',
        size: 300,
        sort: 'name'
      },{
        label: 'Nb. Investments',
        value: '{{nb_investments}}',
        display: '{{nb_investments}}',
        size: 300,
        sort: 'nb_investments'
      },{
        label: 'Investments',
        value: '{{list:investments}}',
        display: '{{link-list:investments}}',
        size: 'extend',
      }
    ],
    sortable: false
  });

  window.search_tables.deals = new Table('#searchDealsTable', {
    endpoint: '/api/multiples/fundraising',
    parameters: null,
    method: 'POST',
    collapseOnScroll: ['.criteria-block'],
    htmlCount: '#dealsCount',
    rowLink: '{{url}}',
    emptyContent: 'No results yet, make a search first.',
    noResultsContent: 'No results for this search',
    columns: [{
        label: 'Startup',
        class: 'startup',
        value: '{{startup}}',
        display: '{{startup}}',
        size: 300,
        sort: 'startup__name'
      },{
        label: 'Stage',
        class: 'stage',
        value: '{{stage}}',
        display: '{{stage}}',
        size: 100
      },{
        label: 'Date in Press',
        class: 'date',
        value: '{{date_in_press}}',
        display: '{{date:M YYYY:date_in_press}}',
        size: 200,
        sort: 'date_in_press'
      },{
        label: 'Investors / Acquirers',
        class: 'investors',
        value: '{{list:investors}}',
        display: '{{link-list:investors}}',
        size: 400
      },{
        label: 'New Money in Press',
        class: 'new-money',
        value: '{{new_money_in_press}}',
        display: '{{money:0:new_money_in_press}}',
        size: 200,
        sort: 'new_money'
      },{
        label: 'TTM Sales',
        class: 'ttm',
        value: '{{ttm}}',
        display: '{{money:0:ttm}}',
        size: 200,
        sort: 'ttm'
      },{
        label: 'Annual Growth',
        class: 'annual-growth',
        value: '{{annual_growth}}',
        display: '{{percentage:2:annual_growth}}',
        size: 200,
        sortable: 'annual_growth'
      },{
        label: 'EV / TTM Sales',
        value: '{{ev_ttm_sales}}',
        display: '{{coefficient:0:ev_ttm_sales}}',
        size: 200,
        sort: 'eqv_ttm_revenue_pre_money'
      }
    ],
    sortable: false
  });

  window.search_tables.exits = new Table('#searchExitsTable', {
    endpoint: '/api/multiples/exit',
    parameters: null,
    method: 'POST',
    collapseOnScroll: ['.criteria-block'],
    htmlCount: '#exitsCount',
    rowLink: '{{url}}',
    emptyContent: 'No results yet, make a search first.',
    noResultsContent: 'No results for this search',
    columns: [{
        label: 'Startup',
        class: 'startup',
        value: '{{startup}}',
        display: '{{startup}}',
        size: 300,
        sort: 'startup__name'
      },{
        label: 'Stage',
        class: 'stage',
        value: '{{stage}}',
        display: '{{stage}}',
        size: 100
      },{
        label: 'Date in Press',
        class: 'date',
        value: '{{date_in_press}}',
        display: '{{date:M YYYY:date_in_press}}',
        size: 200,
        sort: 'date_in_press'
      },{
        label: 'Investors / Acquirers',
        class: 'investors',
        value: '{{list:investors}}',
        display: '{{link-list:investors}}',
        size: 400
      },{
        label: 'New Money in Press',
        class: 'new-money',
        value: '{{new_money_in_press}}',
        display: '{{money:0:new_money_in_press}}',
        size: 200,
        sort: 'new_money'
      },{
        label: 'TTM Sales',
        class: 'ttm',
        value: '{{ttm}}',
        display: '{{money:0:ttm}}',
        size: 200,
        sort: 'ttm'
      },{
        label: 'Annual Growth',
        class: 'annual-growth',
        value: '{{annual_growth}}',
        display: '{{percentage:2:annual_growth}}',
        size: 200,
        sortable: 'annual_growth'
      },{
        label: 'EV / TTM Sales',
        value: '{{ev_ttm_sales}}',
        display: '{{coefficient:0:ev_ttm_sales}}',
        size: 200,
        sort: 'eqv_ttm_revenue_pre_money'
      }
    ],
    sortable: false
  });
});