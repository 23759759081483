var XLSX = require("xlsx");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/themes/base/all.css");

import {get, throttle, clone} from 'lodash';

function getTime() {
    let date = new Date();
    let d = date.getDate(),
        m = date.getMonth(),
        y = date.getFullYear(),
        h = date.getHours(),
        min = date.getMinutes(),
        s = date.getSeconds();
    d = d < 10 ? '0' + d : d;
    m = m < 10 ? '0' + m : m;
    h = h < 10 ? '0' + h : h;
    min = min < 10 ? '0' + min : min;
    s = s < 10 ? '0' + s : s;
    return d + m + y + h + min + s;
  }

/// function @populate
/// Populates given data into given source using substitution syntax
/// @param {String} source input string to work with
/// @param {Object} data an object whose properties will be populated to the source
/// @returns {String} the input after data population
/// Supported substitution:
/// {{<path>.<to>.<key>}} -> value of given property (i.e. data.path.to.key)
function populate(source, data) {
    var t = this;
    var re = /{{([^{}]*?)}}/g;
    var match = null;
  
    // hint: looping on matches made on source while  modifying it at the same
    // time was producing unexpected results
    // we now match on source, untouched, and modify a copy, target
    // because modifications can shift length the target string, we keep a track
    // of the shift and report it to any replacement made to target
    var target = source;
    var lengthShift = 0;
  
    while ((match = re.exec(source)) != null ) {
        var i = match.index + lengthShift;
        if (i > 0 && target[i] == '\\') continue;

        var key = match[1];
        var value = undefined;

        try {
            if (key.startsWith('link:')) {
                value = populateLink(key.split(':')[1], data);
            } else if (key.startsWith('str:')) {
                value = populateString(key.split(':')[1], data);
            } else if (key.startsWith('list:')) {
                value = populateList(key.split(':')[1], data);
            } else if (key.startsWith('link-list:')) {
                value = populateLinkList(key.split(':')[1], data);
            } else if (key.startsWith('token-list:')) {
                value = populateTokenList(key.split(':')[1], data);
            } else if (key.startsWith('money:')) {
                value = populateMoney(key.split(':').slice(1).join(':'), data);
            } else if (key.startsWith('percentage:')) {
                value = populatePercentage(key.split(':').slice(1).join(':'), data);
            } else if (key.startsWith('date:')) {
                value = populateDate(key.split(':').slice(1).join(':'), data);
            } else if (key.startsWith('coefficient:')) {
                value = populateCoefficient(key.split(':').slice(1).join(':'), data);
            } else if (key.startsWith('document:')) {
                value = populateDocument(key.split(':')[1], data);
            } else if (key.startsWith('checkbox:')) {
                value = populateCheckbox(key.split(':')[1], data);
            } else {
                value = get(data, key, undefined);
            }
        } catch (e) {
            console.log(e);
        }
        value = value !== undefined && value !== null ? String(value) : '';

        target = target.substr(0, i) + value + target.substr(i+match[0].length);
        lengthShift += (value.length - match[0].length);
    }
    return target;
}

function populateLink(key, data) {
    let datum = get(data, key);
    if (datum)
        return `<a href="${datum.url}">${datum.value}</a>`;
    else
        return '–';
}

function populateString(key, data) {
    let value = get(data, key);
    if (value != undefined)
        return String(value);
    else
        return '–';
}

function populateList(key, data) {
    data = get(data, key);
    let items = [];
    for (let datum of data) {
        if (typeof datum == 'object') datum = datum.value;
        items.push(datum);
    }
    return items.join(', ');
}

function populateTokenList(key, data) {
    data = get(data, key);
    let items = [];
    for (let datum of data) {
        if (typeof datum == 'object') datum = datum.value;
        items.push('<span class="token rounded-pill">' + datum + '</span>');
    }
    return items.join('');
}

function populateLinkList(key, data) {
    data = get(data, key);
    let links = [];
    for (let datum of data) {
        links.push(`<a href="${datum.url}">${datum.value}</a>`);
    }
    return links.join(', ');
}

function populateMoney(key, data) {

    let optionDecimals = 0;
    let sp = key.split(':');
    if (sp.length > 1) {
        optionDecimals = parseInt(sp[0]);
        key = sp[1];
    }

    let datum = parseFloat(get(data, key));
    let sign = 1;
    if (datum < 0) {
        sign = -1;
        datum = -datum;
    }
    let unit = '€';
    if (datum >= 1000000) {
        datum = datum / 1000000;
        unit = 'M €';
    } else if (datum >= 1000) {
        datum = datum / 1000;
        unit = 'k €';
    }
    datum = sign * Math.round(datum * Math.pow(10, optionDecimals)) / Math.pow(10, optionDecimals);

    if (isNaN(datum))
        return '–';
    else
        return datum + unit;
}

function populatePercentage(key, data) {

    let optionDecimals = 0;
    let sp = key.split(':');
    if (sp.length > 1) {
        optionDecimals = parseInt(sp[0]);
        key = sp[1];
    }

    let datum = parseFloat(get(data, key));
    datum = Math.round(datum * 100 * Math.pow(10, optionDecimals)) / Math.pow(10, optionDecimals);

    if (isNaN(datum))
        return '–';
    else
        return datum + ' %';
}

function populateCoefficient(key, data) {

    let optionDecimals = 0;
    let sp = key.split(':');
    if (sp.length > 1) {
        optionDecimals = parseInt(sp[0]);
        key = sp[1];
    }

    let datum = parseFloat(get(data, key));
    datum = Math.round(datum * Math.pow(10, optionDecimals)) / Math.pow(10, optionDecimals);

    if (isNaN(datum))
        return '–';
    else
        return datum + 'x';
}

function leadingZero(x) {
    x = String(x);
    return x.length == 1 ? ('0' + x) : x;
}

function populateDate(key, data) {

    let optionFormat = undefined;
    let sp = key.split(':');
    if (sp.length > 1) {
        key = sp.splice(sp.length - 1, 1);
        optionFormat = sp.join(':');
    }
    
    let dateString = get(data, key);
    if (dateString == null)
        return '–';
    let date = new Date(Date.parse(dateString));
    
    let d = date.getDate(),
        DD = leadingZero(date.getDate()),
        MM = leadingZero(date.getMonth()+1),
        YYYY = date.getFullYear(),
        YY = String(YYYY).substr(2),
        M = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'][date.getMonth()],
        H = date.getHours(),
        i = leadingZero(date.getMinutes()),
        s = leadingZero(date.getSeconds());
    let output = optionFormat;
    output = output.replace(/DD/g, DD);
    output = output.replace(/MM/g, MM);
    output = output.replace(/YYYY/g, YYYY);
    output = output.replace(/YY/g, YY);
    output = output.replace(/M/g, M);
    output = output.replace(/d/g, d);
    output = output.replace(/H/g, H);
    output = output.replace(/i/g, i);
    return output;
}

function populateDocument(key, data) {
    let document = get(data, key);
    if (!document)
        return '';
    return `<a class="document" href="#" data-target="${document.url}" data-action="document" data-id="${document.id}">${document.file}</a>`;
}

function populateCheckbox(key, data) {
    let value = get(data, key);
    let pk = get(data, 'pk');
    let id_value = data.editor ? `table_checkbox_${pk}_${key}_editor` : `table_checkbox_${pk}_${key}`;
    if (value) {
        return `
        <input type="checkbox" checked="checked" id="${id_value}">
        <label for="${id_value}">
            <span class="yes">Yes</span>
            <span class="no">No</span>
        </label>
        `;
    } else {
        return `
        <input type="checkbox" id="${id_value}">
        <label for="${id_value}">
            <span class="yes">Yes</span>
            <span class="no">No</span>
        </label>
        `;
    }
}

/**
 * @class Table
 * @classdesc Provide interactive tables with autoloading, sorting and other features
 * @prop {String} domSelector
 * CSS selector targeting the element to transform into an interactive table, usually a
 * <table> tag
 * the table will occupy full width and height of its immediate parent
 * @prop {Object} options
 */
export class Table {
    constructor(domSelector, options) {
        var t = this;
        t._options = options;
        t._columns = options.columns;
        t._rows = [];

        t.initLayout(domSelector);
        if (!t._setup.$content) return;

        if (!options.html) {
            /// setup table from scratch
            t.renderHeader();
            
            let emptyContent = t._options.emptyContent || 'No content';
            t.renderEmpty(emptyContent);
            t.resizeTable();
            t.fetch(0, t._setup.countPerPage);
        }

        if (t._options.columnsSelector)
            t.initColumnsSelector(t._options.columnsSelector);
    }

    ///////////////////////////////////////////////////////////////////////////
    // getters

    get parameters() {
        return this._options.parameters;
    }

    get columns() {
        return this._columns;
    }

    ///////////////////////////////////////////////////////////////////////////
    // setters

    set parameters(value) {
        this._options.parameters = value;
    }

    ///////////////////////////////////////////////////////////////////////////
    // fetch

    fetch(page, count, fallback) {
        var t = this;
        if (!t._options.endpoint) return;
        if (t._options.parameters === null) return;
        if (t._fetching || t._maxPage) return;
        t._fetching = true;

        let parameters = {
            p: page,
            c: count
        }
        if (t._options.parameters != undefined) {
            parameters = clone(t._options.parameters);
            parameters.p = page;
            parameters.c = count;
        }

        if (t._setup.sort.length > 0) {
            let sortParameters = [];
            for (let sort of t._setup.sort) {
                if (sort.order == '-')
                    sortParameters.push('-'+sort.key)
                else
                sortParameters.push(sort.key);
            }
            parameters.sort = sortParameters.join(',');
        }

        //t._setup.$tableContent.find('.empty_row').remove();

        // FIXME: loader is not visible all the time
        t.renderLoader(true);
        if (window.dbg) return;
        //t.resizeTable();

        let method = t._options.method == 'POST' ? $.post : $.get;
        if (window.dbg) return;
        setImmediate(() => {
            method(t._options.endpoint, parameters, function( data, status, xhr ) {
                t._setup.page = page;
                let rows = data['results'];
                t._count = data['total'];
                if (t._options.htmlCount) {
                    $(t._options.htmlCount).html(t._count);
                }
                t.renderLoader(false);
                if (t._count == 0) {
                    let noResultsContent = t._options.noResultsContent || 'No content';
                    t.renderEmpty(noResultsContent);
                }
                t.renderRows(rows);
                if (rows.length < count) {
                    t._maxPage = true;
                }
                t._fetching = false;
                if (fallback !== undefined) fallback.call();
              }, 'json')
              .fail(function() {
                t._fetching = false;
              });
        })
    }

    autofetch(count) {
        var t = this;
        if (t._fetching || t._maxPage) return;
        t.fetch(t._setup.page+1, t._setup.countPerPage);
    }

    refresh() {
        var t = this;
        t.clear();
        t.fetch(0, t._setup.countPerPage);
    }

    fetchAll(callback, limit) {
        var t = this;

        if (limit != undefined && limit < t._count)
            t._count = limit;

        if (t._count === undefined) {
            t.fetch(0, t._setup.countPerPage, () => {
                t.fetchAll(callback, limit);
            });
            return;
        } else if (t._count > t._rows.length) {
            let page = Math.round(t._rows.length / t._setup.countPerPage);
            let itemsCount = t._setup.countPerPage;
            if (t._rows.length + itemsCount > t._count)
                itemsCount = t._count - t._rows.length;
            t.fetch(page, itemsCount, () => {
                t.fetchAll(callback, limit);
            })
            return;
        }

        if (callback) callback.call(this);
    }

    ///////////////////////////////////////////////////////////////////////////
    // layout

    initLayout(domSelector) {
        var t = this;

        let $content = $(domSelector);
        if (t._options.endpoint == undefined) {
            t._options.endpoint = $content.data('endpoint');
        }
        if (t._options.editorEndpoint == undefined) {
            t._options.editorEndpoint = $content.data('editor');
        }

        if (t._options.columnsSelector == undefined) {
            t._options.columnsSelector = $content.data('columns-selector');
        }
        if (t._options.exportFilename == undefined) {
            t._options.exportFilename = $content.data('export-filename');
        }
        if (t._options.exportButton == undefined) {
            t._options.exportButton = $content.data('export-button');
            t._options.$exportButton = $(t._options.exportButton);
            t._options.$exportButton.on('click.table', (e) => {
                let filename = t._options.exportFilename;
                filename += '_' + getTime();
                t.export(filename);
            });
        }

        t._setup = {
            page: 0,
            countPerPage: 50,
            maxPage: false,
            $content: $content.length > 0 ? $content : undefined,
            columnSizes: [],
            width: $content.width(),
            height: $content.height(),
            tableWidth: undefined,
            tableHeight: undefined,
            headerHeight: undefined,
            scrollX: 0,
            scrollY: 0,
            sort: [],
        }

        if (!t._setup.$content) return;

        // options.html: true when table is already built in the DOM tree
        if (!t._options.html) {
            t._setup.$content.addClass('dynamic-height');
        }

        // options.static: true when table content is already built in the DOM tree
        // FIXME: there is a bit of confusion with options.html
        if (t._options.static) {
            t.initHeaderFromDOM();
            t.initContentFromDOM();
            t.resizeTable();
        } else {
            t._setup.$tableHeader = $('<div>').addClass('table-header');
            t._setup.$content.append(t._setup.$tableHeader);
            t._setup.$tableContent = $('<div>').addClass('table-content');
            t._setup.$content.append(t._setup.$tableContent);
        }

        let emptyContent = t._options.emptyContent || 'No content';
        t._setup.$tableFooter = t._setup.$tableContent.find('.table-footer');
        if (t._setup.$tableFooter.length === 0) {
            t._setup.$tableFooter = $('<div>').addClass('table-footer')
                .append($('<div class="placeholder">&nbsp;</div>'))
                .append($('<div class="empty">' + emptyContent + '</div>'))
                .append($(`<div class="loader">
                    <div class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>`));
            t._setup.$tableContent.append(t._setup.$tableFooter);
        }

        if (t._options.defaultSort) {
            let key = t._options.defaultSort;
            let order = '+';
            if (key.startsWith('-')) {
                order = '-';
                key = key.slice(1);
            } else if (key.startsWith('+')) {
                key = key.slice(1);
            }
            t._setup.sort = [{key, order}];
        }

        // bind table resize on window resize
        $(window).on('resize', () => {
            t.resizeTable();
        })

        // bind table scroll
        if (!t._options.static) {
            t._setup.$content.on('wheel', throttle((e) => {
                e.preventDefault();
                t.moveScroll(e.originalEvent.deltaX, e.originalEvent.deltaY);
            }, 0));
        }

        // bind cell expanding on hover
        t._setup.$tableContent.on('mousemove', '.row', throttle((e) => {
            let $row = $(e.currentTarget);
            let x = e.originalEvent.clientX - $row.offset().left;
            let acc = 0, hovered = null;
            for (let i = 0; i < t._setup.columnSizes.length; ++i) {
                let size = t._setup.columnSizes[i];
                if (x >= acc && x <= acc + size) {
                    hovered = i;
                    break;
                }
                acc += size;
            }
            if (hovered != null) {
                let $expandedCell = $row.find('.cell.expanded');
                if ($expandedCell.length > 0) {
                    if (parseInt($expandedCell.attr('index') == hovered))
                        return;
                }
        
                t._setup.$tableContent.find('.cell.expanded').removeClass('expanded');
                let $hoveredCell = $row.find(`.cell[index="${hovered}"]`);
                if ($hoveredCell.hasClass('expandable'))
                    $hoveredCell.addClass('expanded');
            }
        }, 1500));

        // bind click on sort buttons
        t._setup.$tableHeader.on('click', 'a.sort', (e) => {
            e.preventDefault();

            let $button = $(e.currentTarget);
            let $column = $button.parents('.cell');
            let order = $button.hasClass('sort-asc') ? '-' : '+';
            let key = $column.attr('sort');
            
            if (!e.shiftKey) t.clearSort();
            
            if (order == '-') {
                $button.addClass('sort-desc').removeClass('sort-asc');
            } else {
                $button.addClass('sort-asc').removeClass('sort-desc');
            }
            t.sortBy(key, order);
        })

        // bind mouse movements on column handles for resize
        t._setup.$tableHeader.on('mousedown', '.handle', (e) => {
            e.preventDefault();
            let $headerCell = $(e.currentTarget).parents('.cell');
            let label = $headerCell.attr('label');
            let $contentCells = t._setup.$tableContent.find(`.cell[label="${label}"]`);
            t._setup.columnResize = {
                startX: e.clientX,
                $headerCell: $headerCell,
                $contentCells: $contentCells,
                column: t._columns.find((c) => c.label == label),
                label: label,
                index: $headerCell.index(),
                size: t._setup.columnSizes[$headerCell.index()],
                newSize: undefined,
                tableWidth: t._setup.tableWidth
            }
        })
        $(window).on('mouseup', (e) => {
            if (t._setup.columnResize) {
                t._setup.columnResize = undefined;
            }
        })
        $(window).on('mousemove', (e) => {
            if (t._setup.columnResize) {
                e.preventDefault();
                let resizeDelta = e.clientX - t._setup.columnResize.startX;
                t._setup.columnResize.newSize = t._setup.columnResize.size + resizeDelta;
                if (t._setup.columnResize.newSize < 40) {
                    t._setup.columnResize.newSize = 40;
                    resizeDelta = t._setup.columnResize.newSize - t._setup.columnResize.size;
                }
                t._setup.columnResize.column.size = t._setup.columnResize.newSize;
                t._setup.columnSizes[t._setup.columnResize.index] = t._setup.columnResize.newSize;
                t._setup.columnResize.$headerCell.width(t._setup.columnResize.newSize);
                t._setup.columnResize.$contentCells.width(t._setup.columnResize.newSize);
                
                t._setup.tableWidth = t._setup.columnResize.tableWidth + resizeDelta;
                t._setup.$tableHeader.width(t._setup.tableWidth);
                t._setup.$tableContent.width(t._setup.tableWidth);
                t.autocropCells();
            }
        });

        // bind exclude buttons on rows
        t._setup.$tableContent.on('click.table', '.row .cell.ex .content a', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let $btn = $(e.currentTarget);
            let $row = $btn.parents('.row');
            $row.toggleClass('excluded');
            let isExcluded = $row.hasClass('excluded');
            t._rows[parseInt($row.attr('index'))].ex = isExcluded ? 1 : 0;
        });

        // bind editor controls
        t._setup.$tableContent.on('click.table', '.row .editor-controls a', (e) => {
            let action = $(e.currentTarget).attr('action');
            if (action == 'edit') {
                t.startEditRow($(e.currentTarget).parents('.table-content .row'));
            } else if (action == 'cancel') {
                t.stopEditRow($(e.currentTarget).parents('.table-content .row'), false);
            } else if (action == 'save') {
                t.stopEditRow($(e.currentTarget).parents('.table-content .row'), true);
            }
        });

        // bind delete row buttons
        t._setup.$tableContent.on('click.table', '.row .cell .content a[action="delete-row"]', (e) => {
            t.startDeleteRow($(e.currentTarget).parents('.table-content .row'));
        });
        // bind deletion controls
        t._setup.$tableContent.on('click.table', '.row .deletion-controls a', (e) => {
            let action = $(e.currentTarget).attr('action');
            if (action == 'cancel') {
                t.stopDeleteRow($(e.currentTarget).parents('.table-content .row'), false);
            } else if (action == 'confirm') {
                t.stopDeleteRow($(e.currentTarget).parents('.table-content .row'), true);
            }
        });
    }

    initHeaderFromDOM() {
        var t = this;
        t._setup.$tableHeader = t._setup.$content.find('.table-header');

        t._columns = [];
        t._setup.$tableHeader.find('.cell').each((index, cell) => {
            let $cell = $(cell);
            let columSetup = {
                label: $cell.find('.content').html(),
                size: $cell.attr('size'),
                key: $cell.attr('key'),
                value: $cell.attr('value'),
                display: $cell.attr('display'),
                editor: $cell.attr('editor'),
                editorPlaceholder: $cell.attr('editor-placeholder'),
            };
            if ($cell.attr('sort')) {
                columSetup.sort = $cell.attr('sort');
                // generate sorting buttons if not present
                if ($cell.find('a[_action="sort"]').length == 0) {
                    $cell.find('.content').append($(`<a href="#" class="sort" _action="sort">`).html('&nbsp;'));
                }
            }
            t._columns.push(columSetup);
        });
    }

    initContentFromDOM() {
        var t = this;
        t._setup.$tableContent = t._setup.$content.find('.table-content');

        // setup rows from DOM rows
        t._setup.$tableContent.find('.row').each((index, row) => {
            let $row = $(row);
            let rowData = {};
            $row.find('.cell').each((index, cell) => {
                let $cell = $(cell);
                let key = $cell.attr('key'), value = $cell.attr('value');
                if (key) {
                    rowData[key] = value;
                }    
            })

            if ($row.attr('editable')) {
                // generate editor control block if not present
                if ($row.find('.editor-controls').length == 0) {
                    $row.prepend($('<div>').addClass('editor-controls')
                            .append($('<a>').addClass('btn btn-light rounded-pill').attr('href', '#').attr('title', 'Edit row').attr('action', 'edit').append($('<i>').addClass('fas fa-pen')))
                            //.append($('<a>').addClass('btn btn-light rounded-pill').attr('href', '#').attr('title', 'Edit row').attr('action', 'delete').append($('<i>').addClass('fas fa-trash')))
                            .append($('<a>').addClass('btn btn-success rounded-pill').attr('href', '#').attr('title', 'Save').attr('action', 'save').append($('<i>').addClass('fas fa-check-circle')))
                            .append($('<a>').addClass('btn btn-danger rounded-pill').attr('href', '#').attr('title', 'Cancel').attr('action', 'cancel').append($('<i>').addClass('fas fa-times-circle')))
                    );
                }
            }

            t._rows.push({$row, data: rowData});
        });
    }

    resizeTable() {
        var t = this;

        t._setup.width = t._setup.$content.width();
        t._setup.height = t._setup.$content.height();

        let tableWidth = 0;
        let extendableColumns = [], occupiedSize = 0;
        for (let i = 0 ; i < t._columns.length; ++i) {
            let column = t._columns[i];
            let hidden = column.hidden !== undefined ? column.hidden : false;
            if (hidden) continue;

            if (column.size == 'extend') {
                extendableColumns.push(i);
            } else {
                column.size = parseInt(column.size);
                t._setup.columnSizes[i] = column.size;
                occupiedSize += column.size;
                tableWidth += column.size;
            }
        }

        let extendedSize = 100;
        if (occupiedSize < t._setup.width) {
            extendedSize = Math.round((t._setup.width - occupiedSize) / extendableColumns.length);
        }
        for (let i of extendableColumns) {
            t._setup.columnSizes[i] = extendedSize;
            tableWidth += extendedSize;
        }

        t._setup.$tableHeader.find('.cell').each((i, cell) => {
            $(cell).width(t._setup.columnSizes[i]);
        })
        t._setup.$tableContent.find('.row').each((i, row) => {
            $(row).find('.cell').each((i, cell) => {
                $(cell).width(t._setup.columnSizes[i]);
            });
        });

        if (t._setup.$tableFooter) {
            t._setup.$tableFooter.width(t._setup.width);
            if (t._setup.$tableFooter.find('.placeholder').length > 0)
                t._setup.footerHeight = t._setup.$tableFooter.height();
        }

        //t._setup.$tableContent.width(undefined);
        t._setup.$tableHeader.width(undefined);
        //t._setup.tableWidth = t._setup.$tableHeader[0].scrollWidth;
        t._setup.tableWidth = tableWidth;
        //t._setup.tableHeight = t._setup.$tableContent[0].scrollHeight;        
        t._setup.tableHeight = t._rows.length > 0 ? t._rows.length * t._rows[0].$row.outerHeight(true) : 0;
        t._setup.headerHeight = t._setup.$tableHeader.height();
        t._setup.$tableHeader.width(t._setup.tableWidth);
        t._setup.$tableContent.width(t._setup.tableWidth);

        //t._setup.$tableFooter.css('top', Math.round(t._setup.tableHeight) + 'px');

        t.autocropCells();
    }

    moveScroll(x, y) {
        var t = this;
        if (x !== undefined) t._setup.scrollX += x;
        if (y !== undefined) t._setup.scrollY += y;
        t.setScroll();
    }

    setScroll(x, y) {
        var t = this;

        if (x !== undefined) t._setup.scrollX = x;
        if (y !== undefined) t._setup.scrollY = y;

        if (t._setup.scrollX > (t._setup.tableWidth - t._setup.width))
            t._setup.scrollX = t._setup.tableWidth - t._setup.width;
        if (t._setup.scrollY > 0 && (t._setup.scrollY > (t._setup.tableHeight - t._setup.height + t._setup.headerHeight + t._setup.footerHeight))) {
            t._setup.scrollY = t._setup.tableHeight - t._setup.height + t._setup.headerHeight + t._setup.footerHeight;
            t.autofetch(30);
        }

        if (t._setup.scrollX < 0) t._setup.scrollX = 0;
        if (t._setup.scrollY < 0) t._setup.scrollY = 0;
        this._setup.$tableHeader.css('transform', `translate3d(-${t._setup.scrollX}px, 0, 0)`);
        this._setup.$tableFooter.css('transform', `translate3d(${t._setup.scrollX}px, 0, 0)`);
        this._setup.$tableContent.css('transform', `translate3d(-${t._setup.scrollX}px, -${t._setup.scrollY}px, 0)`);

        if (t._options.collapseOnScroll) {
            for (let selector of t._options.collapseOnScroll) {
                if (t._setup.scrollY > 40)
                    $(selector).hide();
                else
                    $(selector).show();
            }
        }
    }

    autocropCells() {
        var t = this;
        // detect expandable cells and mark them as such
        // cells having 'autocrop' classes will have their 
        // content cropped after their last visible child

        t._setup.$tableContent.find('.cell.autocrop .cropped').removeClass('cropped');
        t._setup.$tableContent.find('.cell.expandable').removeClass('expandable expanded');
        t._setup.$tableContent.find('.cell').each((i, cell) => {
            let $cell = $(cell);
            let content = $cell.find('.content')[0];
            if (content.offsetWidth < content.scrollWidth) {
                $cell.addClass('expandable');
            }
        });

        t._setup.$tableContent.find('.cell.autocrop').each((i, cell) => {
            let $cell = $(cell);
            let maxOffset = $cell.offset().left + $cell.innerWidth();
            $cell.find('.content >').each((j, child) => {
                let $child = $(child);
                let childOffset = $child.offset().left + $child.outerWidth();
                $child.attr('offset', childOffset);
                if (childOffset < maxOffset)
                    $child.removeClass('cropped');
                else
                    $child.addClass('cropped');
            })
        })
    }

    renderEmpty(content) {
        var t = this;
        t._setup.$tableFooter.removeClass('loading');
        t._setup.$tableFooter.addClass('empty');
        t._setup.$tableFooter.find('.empty').html(content);
    }

    renderLoader(active) {
        var t = this;
        t._setup.$tableFooter.removeClass('empty loading');
        if (t._options.htmlLoader)
            $(t._options.htmlLoader).removeClass('active');
        if (active) {
            t._setup.$tableFooter.addClass('loading');
            if (t._options.htmlLoader)
                $(t._options.htmlLoader).addClass('active');
        }   
    }

    ///////////////////////////////////////////////////////////////////////////
    // rendering columns

    renderHeader() {
        var t = this;
        for (let column of t._columns) {
            let userClass = column.class !== undefined ? column.class : '';
            let sortKey = column.sort ? column.sort : '';

            let sortActive = t._setup.sort.find((s) => s.key == sortKey);
            let sortActiveClass = '';
            if (sortActive) {
                sortActiveClass = sortActive.order == '+' ? 'sort-asc' : 'sort-desc';
            }

            let $cell = $($.parseHTML($.trim(`
                <div class="cell ${userClass}" sort="${sortKey}" label="${column.label}">
                    <div class="content">${column.label}</div>
                    <div class="handle"></div>
                </div>
            `)));
            if (column.sort) {
                $cell.find('.content').append($(`<a href="#" class="sort ${sortActiveClass}" _action="sort">`).html('&nbsp;'))
            }
            if (column.hidden)
                $cell.hide();
            $cell.appendTo(t._setup.$tableHeader);
            //$($.parseHTML(t.populateData($.trim(content))))
        }
        t.resizeTable();
    }


    ///////////////////////////////////////////////////////////////////////////
    // rendering rows

    refreshContent() {
        var t = this;
        if (t._rows.length == 0) return;

        // recompute all existing rows and replace them
        // does not affect layout, in particular current scroll remains
        for (let i = 0; i < t._rows.length; ++i) {
            let row = t._rows[i];
            let $newRow = t.renderRow(row.data).$row;
            $newRow.insertAfter(row.$row);
            row.$row.remove();
            t._rows[i].row = $newRow;
        } 
        t.resizeTable();
    }

    renderRows(rows) {
        var t = this;

        // if (rows.length > 0) {
        //     t._setup.$tableContent.find('.empty_row').remove();
        // }

        for (let row of rows) {
            row = t.renderRow(row, t._rows.length);
            let $row = row.$row;
            t._rows.push(row);
            //t._setup.$tableContent.append($row);
            t._setup.$tableFooter.before($row);
        }
        t.resizeTable();
    }

    renderRow(data, index) {
        var t = this;

        //let data = [];
        let $row = $('<div>').addClass('row').attr('index', index);
        if (data.id)
            $row.attr('id', data.id);
        //for (let column of t._columns) {
        let rowIsEditable = false;
        let rowIsDeletable = t._options.controls && t._options.controls.indexOf('deleteRow') >= 0;
        for (let columnIndex = 0; columnIndex < t._columns.length; ++columnIndex) {
            let column = t._columns[columnIndex],
            display = populate(column.display, data);

            let userClass = column.class != undefined ? column.class : '';
            let contentClass = column.style != undefined ? column.style : '';
            let editableAttr = column.editor != undefined ? 'editable="editable"' : '';

            let cellStr = `<div class="cell ${userClass}" key="${column.key}" label="${column.label}" index="${columnIndex}" ${editableAttr}>
                <div class="content ${contentClass}">${display}</div>
            `;
            if (column.editor != undefined) {
                let editorData = Object.assign({editor: true}, data);
                rowIsEditable = true;
                // FIXME: ugly fix, better add a populate method {{input:...}}
                if (column.editor.startsWith('{{checkbox:')) {
                    let editor = populate(column.editor, editorData);
                    cellStr += `<div class="editor">${editor}</div>`
                } else {
                    let editorContent = populate(column.editor, editorData);
                    cellStr += `<div class="editor"><input type="text" placeholder="${column.editorPlaceholder}" value="${editorContent}"></div>`
                }
            }
            cellStr += '</div>'
            let $cell = $($.parseHTML($.trim(cellStr)));

            if (column.hidden)
                $cell.hide();

            $cell.appendTo($row);
        }
        if (rowIsEditable) {
            $row.attr('editabled', 'editable');
            $row.prepend($('<div>').addClass('editor-controls')
                .append($('<a>').addClass('btn btn-light rounded-pill').attr('href', '#').attr('title', 'Edit row').attr('action', 'edit').append($('<i>').addClass('fas fa-pen')))
                //.append($('<a>').addClass('btn btn-light rounded-pill').attr('href', '#').attr('title', 'Edit row').attr('action', 'delete').append($('<i>').addClass('fas fa-trash')))
                .append($('<a>').addClass('btn btn-success rounded-pill').attr('href', '#').attr('title', 'Save').attr('action', 'save').append($('<i>').addClass('fas fa-check-circle')))
                .append($('<a>').addClass('btn btn-danger rounded-pill').attr('href', '#').attr('title', 'Cancel').attr('action', 'cancel').append($('<i>').addClass('fas fa-times-circle')))
            );
        }
        if (rowIsDeletable) {
            $row.attr('deletable', 'deletable');
            $row.prepend($('<div>').addClass('deletion-controls')
                .append($('<a>').addClass('btn btn-success rounded-pill').attr('href', '#').attr('title', 'Save').attr('action', 'confirm').append($('<i>').addClass('fas fa-check-circle')))
                .append($('<a>').addClass('btn btn-danger rounded-pill').attr('href', '#').attr('title', 'Cancel').attr('action', 'cancel').append($('<i>').addClass('fas fa-times-circle')))
            );
        }
        if (t._options.rowLink !== undefined) {
            let $rowLink = $('<a>')
                .addClass('row-link')
                .attr('href', populate(t._options.rowLink, data));
            $row.prepend($rowLink);
            //$row.attr('href', populate(t._options.rowLink, data));
        }
        if (data.selected) {
            $row.addClass('selected');
        }
        return {data: data, $row: $row, index};
    }

    clear() {
        var t = this;
        t._rows = [];
        //t._setup.$tableContent.empty();
        t._setup.$tableContent.find('.row').remove();
        // if (t._options.htmlCount) {
        //     $(t._options.htmlCount).html('');
        // }
        t.setScroll(undefined, 0);
        t.resizeTable();

        t._maxPage = false;
        t._fetching = false;
    }

    ///////////////////////////////////////////////////////////////////////////
    // sorting

    sortBy(key, order) {
        var t = this;
        for (let sort of t._setup.sort) {
            if (sort.key == key) {
                sort.order = order;
                t.refresh();
                return;
            }
        }
        t._setup.sort.push({key: key, order: order})
        t.refresh();
    }

    clearSort() {
        var t = this;
        t._setup.sort = [];
        t._setup.$tableHeader.find('a.sort').removeClass('sort-desc sort-asc');
    }

    ///////////////////////////////////////////////////////////////////////////
    // columns selector

    initColumnsSelector($elt) {
        var t = this;
        t._$columnsSelector = $($elt);
        if (t._$columnsSelector.length == 0) return;

        for (let column of t._columns) {
            let $columnSelector = t._buildColumnSelector(column);
            $columnSelector.appendTo(t._$columnsSelector);
        }

        t._$columnsSelector.sortable({
            update: (e, ui) => { t._setColumnsSelection() }
        });
        t._$columnsSelector.on('click.tables', 'a.btn', (e) => {
            let $btn = $(e.currentTarget);
            $btn.toggleClass('disabled');
            let column = $btn.data('label');
            if ($btn.hasClass('disabled')) {
                t.hideColumn(column);
            } else {
                t.showColumn(column);
            }
        })
    }

    _buildColumnSelector(column) {
        return $('<a>').attr('href', '#')
            .addClass('btn btn-secondary rounded selected column-setup')
            .attr('data-label', column.label)
            .html(column.label);
    }

    _setColumnsSelection() {
        var t = this;
        let orderedColumns = [];
        t._$columnsSelector.find('a.btn').each((index, elt) => {
            let $elt = $(elt);
            orderedColumns.push($elt.data('label'));
        });
        t.orderColumns(orderedColumns);
    }

    ///////////////////////////////////////////////////////////////////////////
    // order columns

    orderColumns(labels) {
        var t = this;

        let newColumns = [];
        // first collect given columns in given order
        for (let label of labels) {
            let column = t._columns.find((c) => c.label == label);
            if (column) {
                newColumns.push(column);
            }
        }
        // then collect remaining columns and add them in their current order
        for (let column of t._columns) {
            if (newColumns.find((c) => c.label == column.label) == undefined) {
                newColumns.push(column);
            }
        }
        t._columns = newColumns;

        t._setup.$tableHeader.empty();
        t.renderHeader();
        t.refreshContent();
    }

    ///////////////////////////////////////////////////////////////////////////
    // show/hide columns

    hideColumns(labels) {
        var t = this;
        for (let label of labels) {
            let column = t._columns.find((c) => c.label == label);
            if (column) t.hideColumn(label, false);
        }
        t.resizeTable();
    }

    showColumns(labels) {
        var t = this;
        for (let label of labels) {
            let column = t._columns.find((c) => c.label == label);
            if (column) t.showColumn(label, false);
        }
        t.resizeTable();
    }

    hideColumn(label, resize) {
        resize = resize != undefined ? resize : true;
        var t = this;
        t._columns.find((c) => c.label == label).hidden = true;
        t._setup.$tableHeader.find(`.cell[label="${label}"]`).hide();
        t._setup.$tableContent.find(`.cell[label="${label}"]`).hide();
        if (resize)
            t.resizeTable();
    }
    
    showColumn(label, resize) {
        resize = resize != undefined ? resize : true;
        var t = this;
        t._columns.find((c) => c.label == label).hidden = false;
        t._setup.$tableHeader.find(`.cell[label="${label}"]`).show();
        t._setup.$tableContent.find(`.cell[label="${label}"]`).show();
        if (resize)
            t.resizeTable();
    }

    ///////////////////////////////////////////////////////////////////////////
    // edition

    startEditRow($row) {
        var t = this;
        $row.addClass('in-edit');
        $row.find('.cell[editable]').each((index, cell) => {
            let $cell = $(cell);
            let editorValue = $cell.find('.editor input').val();
            $cell.find('.editor').attr('initial', editorValue);
        });
    }

    stopEditRow($row, saveChanges) {
        var t = this;
        saveChanges = saveChanges === undefined ? false : saveChanges;
        if (saveChanges) {
            t.saveRowChanges($row);
        } else {
            $row.removeClass('in-edit');
            // restore initial editor values
            $row.find('.cell[editable]').each((index, cell) => {
                let $cell = $(cell);
                let initialValue = $cell.find('.editor').attr('initial');
                $cell.find('.editor input').val(initialValue);
            });
        }
    }

    saveRowChanges($row) {
        var t = this;
        let rowData = {
            id: $row.attr('id')
        };
        $row.find('.cell').each((index, cell) => {
            let $cell = $(cell),
                key = $cell.attr('key');
            if ($cell.attr('editable') && key) {
                let value = undefined;
                if ($cell.find('.editor input[type="checkbox"]').length > 0) {
                    value = $cell.find('.editor input').is(':checked');
                } else {
                    value = $cell.find('.editor input').val();
                } 
                rowData[key] = value;
            }
        });
        $.post(t._options.editorEndpoint, rowData,
        function( data, status, xhr ) {
            // populate saved data to contents
            for (let key in data.data) {
                let $cell = $row.find(`.cell[key="${key}"]`);
                if ($cell.length) {
                    let displayFormat = t._columns.find((c) => c.key == key).display;
                    $cell.find('.content').html(populate(displayFormat, data.data));
                }
            }
            $row.removeClass('in-edit');
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // deletion

    startDeleteRow($row) {
        var t = this;
        $row.addClass('in-delete');
    }

    stopDeleteRow($row, confirmDeletion) {
        var t = this;
        confirmDeletion = confirmDeletion === undefined ? false : confirmDeletion;
        if (confirmDeletion) {
            t.deleteRow($row);
        } else {
            $row.removeClass('in-delete');
        }
    }

    deleteRow($row) {
        var t = this;
        let rowData = {
            id: $row.attr('id')
        };
        $.get(t._options.editorEndpoint, rowData,
            function( data, status, xhr ) {
                t.refresh();
            }
        );
    }

    ///////////////////////////////////////////////////////////////////////////
    // export

    export(filename) {
        var t = this;
        if (t._count > t._rows.length) {
            t.fetchAll(() => { t.export(filename) }, 500);
            return
        }

        var wb = XLSX.utils.book_new();

        var ws_data = [];
        var header = [];
        
        for (let column of t._columns) {
            if (!column.hidden && column.value != '{{ex}}')
                header.push(column.label);
        }
        ws_data.push(header);

        for (let row of t._rows) {
            let cells = [];
            let rowIsExcluded = false;
            for (let column of t._columns) {
                if (column.value == '{{ex}}') {
                    rowIsExcluded = !!row.ex;
                    if (rowIsExcluded) break;
                    continue;
                }
                if (!column.hidden) {
                    let value = populate(column.value, row.data);
                    let cellType = column.exportType ? column.exportType : 'string';
                    if (cellType == 'number') {
                        value = parseFloat(value);
                        if (isNaN(value)) {
                            cellType = 'blank';
                            value = '';
                        }
                    }
                    cells.push({
                        v: value,
                        t: {
                            'string': 's',
                            'number': 'n',
                            'blank': 'z'
                        }[cellType] // conversion to data types supported by js-xlsx
                    })
                }
            }
            if (!rowIsExcluded) {
                ws_data.push(cells);
            }
        }

        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        window.ws = ws;
        XLSX.utils.book_append_sheet(wb, ws, filename);
    
        var fn = filename + '.xlsx';
        setImmediate(() => {
            XLSX.writeFile(wb, fn);
        })
    }
}



///////////////////////////////////////////////////////////////////////////
// auto setup html tables

export default $(document).ready((e) => {
    $('table.html, div.table.html').each((index, elt) => {
        let table = new Table(elt, {
            html: true,
            static: $(elt).hasClass('static')
        });
        let tableName = $(elt).attr('name');
        if (tableName) window[tableName] = table;
    })
});
