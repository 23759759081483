export default $(document).ready((e) => {

    if ($('.page.reporting').length == 0) return;

    let $documentTable = $('.page.reporting .table');

    // open up a document from reporting table
    $documentTable.on('click.avolta.widget', 'a[data-action="document"]', (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.documentViewer.open($(e.currentTarget));
    });

    $(window).on('document.avolta.new', (e, document) => {
        let row =  {
            id: document.id,
            document: {
                id: document.id,
                file: document.file,
                url: document.url
            }            
        }
        window.reportingTable.renderRows([row]);
    })

});