export default $(document).ready((e) => {

  $('.nav.nav-tabs').on('click', '.nav-link', (e) => {
    e.preventDefault();
    let $clickedNavLink = $(e.currentTarget),
        $clickedNavItem = $clickedNavLink.parents('.nav-item');

    $clickedNavItem.siblings().each((index, navItem) => {
      let $navLink = $(navItem).find('.nav-link');
      $navLink.removeClass('active');
      let target = $navLink.attr('target');
      $(target).removeClass('active');
    });
    $clickedNavLink.addClass('active');
    let target = $clickedNavLink.attr('target');
    $(target).addClass('active');
  })
})