import Chart from 'chart.js';

class ActivityChart {

  constructor($elt) {
    this._$elt = $elt;
    this._setup = {};
    this._data = {};

    if (this._$elt.lenght == 0)
      throw new Error('DOM element was not provided');
    else if (this._$elt.lenght > 1)
      throw new Error('more than a single DOM element was provided');
    
    this.initSetup();
    this.fetchData();
    this.setData();

    let ctx = this._$elt.get(0).getContext('2d');
    this._chart = new Chart(ctx, this._setup);

    // attach data to the chart instance so it can be used
    // to render the tooltip
    this._chart._externalData = this._data;
  }

  initSetup() {
    var t = this;
    t._setup = {
      type: 'bar',
      lineTension: 0,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        legend: {
          display: false
        },
        
        tooltips: {
          enabled: false, // Disable the on-canvas tooltip
          callbacks: {
            label: function(tooltipItem, data) {
              var i = tooltipItem.index;
              return [
                this._chartInstance._externalData.formattedRaised[i],
                this._chartInstance._externalData.dealsNbr[i],
                this._chartInstance._externalData.deals[i]]
            }
          },
          custom: t.tooltip
        },

        animation: {
          duration: 1000,
          onComplete: function(animation) {
            if (typeof animationCallback === "function") {
              // Execute custom animate/update complete callback function​
              animationCallback();
            }
          }
        },

        scales: {
          xAxes: [{
            display: true,
            ticks: {
              callback: function(dataLabel, index) {
                return (index == 0 || index == 6 || index == 11) ? dataLabel : '';
              },
              maxRotation: 0, minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'year'
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: false,
              labelString: 'revenue',
            },
            ticks: {
              min: 0,
              //max: axeYMax,
              fontColor: '#6F82B3',
              reverse: false,
              callback: function(value, index, values) {
                return null
                //return (value % 50) ? null : (value/1000000).toLocaleString() + ' m€';
              }
            },
            gridLines: {
              display: false,
              color: '#E9ECF4',
              drawBorder: false,
            }
          }]
        }

      }
    }
  }

  fetchData() {
    var t = this;
    t._data = {
      labels: [],
      raised: [],
      formattedRaised: [],
      dealsNbr: [],
      deals: []
    };

    let dataTarget = t._$elt.data('target');
    let $data = $(dataTarget);

    $data.find('.activity').each((index, row) => {
      let $row = $(row);
      let label = $row.data('month') + ' ' + $row.data('year');
      let raised = parseFloat($row.data('raised'));
      let formattedRaised = $row.data('raised-format');
      let dealsNbr = $row.find('.deal').length;
      let deals = [];
      $row.find('.deal').each((j, deal) => {
        let $deal = $(deal);
        deals.push({
          startup: $deal.data('startup'),
          nm: $deal.data('nm-format')
        });
      });
      t._data.labels.push(label);
      t._data.raised.push(raised);
      t._data.formattedRaised.push(formattedRaised);
      t._data.dealsNbr.push(dealsNbr);
      t._data.deals.push(deals);
    });
  }

  setData() {
    var t = this;

    t._setup.data = {
      labels: t._data.labels,
      datasets: [{
        label: 'Raised',
        type: 'line',
        data: t._data.raised.map((x) => .6 * x),
        borderColor: '#4C7DFF',
        borderWidth: 4,
        fill: false,
        lineTension: 0,
        pointRadius: 0
      },{
        label: 'Raised',
        data: t._data.raised,
        backgroundColor: '#f0f3fc',
        borderWidth: 0
      },]
    }
  }

  tooltip(tooltipModel) {
    // Tooltip Element
    var $tooltip = $('#chartjs-tooltip');

    // Create element on first render
    if ($tooltip.length == 0) {
      $tooltip = $('<div>').attr('id', 'chartjs-tooltip')
        .css('opacity', '0');
      $('body').append($tooltip);
    }
    $tooltip.addClass('activity-chart-tooltip');
    //$tooltip.empty();

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      $tooltip.css('opacity', '0');
      return;
    }

    // Set caret Position
    $tooltip.removeClass('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      $tooltip.addClass(tooltipModel.yAlign);
    } else {
      $tooltip.addClass('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      $tooltip.empty();
      var titleLines = tooltipModel.title || [];
      var bodyLines = tooltipModel.body.map(getBody);

      // var trustScore = parseInt(bodyLines[0][1]);
      // var offset = 90 - Math.pow(trustScore/100, 2.0) * 50;
      // //var trustScoreColor = 'hsl(223,'+offset+'%,'+offset+'%)';
      // var saturation = trustScore/100 * (68 - 12) + 12;
      // var light = 47.5;
      // var trustScoreColor = 'hsl(223,'+saturation+'%,'+light+'%)';
      // console.log(trustScoreColor);

      var $content = $('<div>').addClass('chart-tooltip-content');
      $content.append($('<p>').addClass('month').html(titleLines[0]));
      $content.append($('<p>').addClass('raised').html(bodyLines[0][0]));
      $content.append($('<p>').addClass('deals-nbr')
      .append($('<span>').addClass('label').html('Deals nbr'))
      .append($('<span>').addClass('value').html(bodyLines[0][1]))
      );
      for (let deal of bodyLines[0][2]) {
        $content.append($('<div>').addClass('deal')
        .append($('<span>').addClass('deal').html(deal.startup))
        .append($('<span>').addClass('nm').html(deal.nm))
        );
      }

      $tooltip.append($content);
      $tooltip.append($('<div>').addClass('chart-tooltip-tip'));
    }

    // Display, position, and set styles for font
    $tooltip.css('opacity', 1);
    $tooltip.css('position', 'absolute');
    var offset = $(this._chart.canvas).offset();
    $tooltip.css('left', tooltipModel.caretX + offset.left);
    $tooltip.css('top', tooltipModel.caretY + offset.top - $tooltip.height() + 7);
  }

}


export default $(document).ready((e) => {

  $('.activity-chart').each((index, elt) => {
    window.chart = new ActivityChart($(elt));
  })


})