export const addMessage = (content, level) => {
    let $msg = $('<div>').addClass('message bg-white border rounded shadow p-3 m-1')
        .addClass(level)
        .html(content);
    $msg.appendTo($('.messages .message-list'));
    setTimeout(() => {
        fadeMessage($msg);
    }, 4000);
}

export const fadeMessage = ($msg) => {
    $msg.addClass('faded');
    setTimeout(() => {
        $msg.remove();
    }, 1000);
}

export default $(document).ready((e) => {
    // schedule automatic fading
    $('.messages .message').each((index, msg) => {
        let $msg = $(msg);
        setTimeout(() => {
            fadeMessage($msg);
        }, 4000 + index * 1500);
    });
})