import Chart from 'chart.js';

class RevenueChart {

  constructor($elt) {
    this._$elt = $elt;
    this._setup = {};
    this._data = {};

    if (this._$elt.lenght == 0)
      throw new Error('DOM element was not provided');
    else if (this._$elt.lenght > 1)
      throw new Error('more than a single DOM element was provided');
    
    this.initSetup();
    this.fetchData();
    this.setData();

    let ctx = this._$elt.get(0).getContext('2d');
    this._chart = new Chart(ctx, this._setup);

    // attach data to the chart instance so it can be used
    // to render the tooltip
    this._chart._externalData = this._data;
  }

  initSetup() {
    var t = this;
    t._setup = {
      type: 'bar',
      lineTension: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        
        tooltips: {
          enabled: false, // Disable the on-canvas tooltip
          callbacks: {
            label: function(tooltipItem, data) {
              var i = tooltipItem.index;
              return [
                this._chartInstance._externalData.formattedValues[i],
                this._chartInstance._externalData.trustScores[i],
                this._chartInstance._externalData.sources[i],
                this._chartInstance._externalData.comments[i]]
            }
          },
          custom: t.tooltip
        },

        animation: {
          duration: 1000,
          onComplete: function(animation) {
            if (typeof animationCallback === "function") {
              // Execute custom animate/update complete callback function​
              animationCallback();
            }
          }
        },

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'year'
            },
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: false,
              labelString: 'revenue',
            },
            ticks: {
              suggestedMin: 0,
              beginAtZero: 0,
              fontColor: '#6F82B3',
              reverse: false,
              callback: function(value, index, values) {
                return (value % 50) ? null : (value/1000000).toLocaleString() + ' m€';
              }
            },
            gridLines: {
              display: true,
              color: '#E9ECF4',
            }
          }]
        }

      }
    }
  }

  fetchData() {
    var t = this;
    t._data = {
      labels: [],
      values: [],
      formattedValues: [],
      sources: [],
      comments: [],
      trustScores: [],
      meta: {
        colors: []
      }
    };

    let dataTarget = t._$elt.data('target');
    let $data = $(dataTarget);

    t._data.meta.max = undefined;

    let lastYear = new Date().getFullYear() - 1, firstYear = undefined;
    $data.find('input').each((index, row) => {
      let $row = $(row);
      let year = parseInt($row.data('label'));
      if (firstYear === undefined || year < firstYear)
      firstYear = year;
    });

    for (let year = firstYear; year <= lastYear; ++year) {
      let $row = $data.find(`input[data-label="${year}"]`);
      if ($row.length > 0) {
        t._data.labels.push($row.data('label'));
        t._data.formattedValues.push($row.data('formatted-value'));
        t._data.trustScores.push($row.data('trust-score'));
        t._data.sources.push($row.data('source'));
        t._data.comments.push($row.data('comment'));
  
        let value = parseFloat($row.data('value'));
        t._data.values.push(value);
        if (t._data.meta.max === undefined || value > t._data.meta.max)
          t._data.meta.max = value;
  
        let trustScore = parseInt($row.data('trust-score'));
        if (isNaN(trustScore)) trustScore = 0;      
        let hue = 223,
            saturation = 50,
            light = (1-trustScore/100) * (78 - 32) + 32;
        t._data.meta.colors.push('hsl(223,'+saturation+'%,'+light+'%)');
      } else {
        t._data.labels.push(year);
        t._data.values.push(undefined);
        t._data.formattedValues.push(undefined);
        t._data.trustScores.push(undefined);
        t._data.sources.push(undefined);
        t._data.comments.push(undefined);
        t._data.meta.colors.push(undefined);
      }
    }
  }

  setData() {
    var t = this;

    t._setup.data = {
      labels: t._data.labels,
      datasets: [{
        label: 'Revenue',
        data: t._data.values,
        backgroundColor: t._data.meta.colors,
        borderWidth: 0
      },]
    }
  }

  tooltip(tooltipModel) {
    // Tooltip Element
    var $tooltip = $('#chartjs-tooltip');

    // Create element on first render
    if ($tooltip.length == 0) {
      $tooltip = $('<div>').attr('id', 'chartjs-tooltip')
        .css('opacity', '0');
      $('body').append($tooltip);
    }
    $tooltip.addClass('ttm-chart-tooltip');
    //$tooltip.empty();

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      $tooltip.css('opacity', '0');
      return;
    }

    // Set caret Position
    $tooltip.removeClass('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      $tooltip.addClass(tooltipModel.yAlign);
    } else {
      $tooltip.addClass('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      $tooltip.empty();
      var titleLines = tooltipModel.title || [];
      var bodyLines = tooltipModel.body.map(getBody);

      // var trustScore = parseInt(bodyLines[0][1]);
      // var offset = 90 - Math.pow(trustScore/100, 2.0) * 50;
      // //var trustScoreColor = 'hsl(223,'+offset+'%,'+offset+'%)';
      // var saturation = trustScore/100 * (68 - 12) + 12;
      // var light = 47.5;
      // var trustScoreColor = 'hsl(223,'+saturation+'%,'+light+'%)';
      // console.log(trustScoreColor);

      var $content = $('<div>').addClass('chart-tooltip-content');
      $content.append($('<p>').addClass('year').html(titleLines[0]));
      $content.append($('<p>').addClass('revenue').html(bodyLines[0][0]));
      $content.append($('<div>').addClass('trust-score')
        .append($('<span>').addClass('label').html('Trust score:'))
        .append($('<span>').addClass('value').html(bodyLines[0][1])/*.css('color', trustScoreColor)*/)
      );
      $content.append($('<div>').addClass('source')
        .append($('<span>').addClass('label').html('Source:'))
        .append($('<span>').addClass('value').html(bodyLines[0][2]))
      );
      $content.append($('<p>').addClass('comment').html(bodyLines[0][3]));

      $tooltip.append($content);
      $tooltip.append($('<div>').addClass('chart-tooltip-tip'));
    }

    // Display, position, and set styles for font
    $tooltip.css('opacity', 1);
    $tooltip.css('position', 'absolute');
    var offset = $(this._chart.canvas).offset();
    $tooltip.css('left', tooltipModel.caretX + offset.left);
    $tooltip.css('top', tooltipModel.caretY + offset.top - $tooltip.height() + 7);
  }

}


export default $(document).ready((e) => {

  $('.revenue-chart').each((index, elt) => {
    new RevenueChart($(elt));
  })


})