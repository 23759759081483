import {Table} from './table';

const saveSearch = () => {
    let searchParameters = window.search.searchParameters();
    let label =  $('#savedSearches form [name="label"]').val();
    if (label.length == 0) {
        return;
    }
    let context =  $('#savedSearches form [name="context"]').val();

    searchParameters['label'] = label;
    searchParameters['context'] = context;
    setImmediate(() => {
        $.post('/api/create-save-search', searchParameters, function( data, status, xhr ) {
            window.savedSearchesTable.refresh();
          }, 'json')
          .fail(function() {
            
          });
    })
}

export default $(document).ready((e) => {
    window.savedSearchesTable = new Table('#savedSearchesTable', {
        rowLink: '{{url}}',
        noResultsContent: 'No search was saved yet.',
        controls: ['deleteRow'],
        editorEndpoint: '/api/delete-saved-search',
        columns: [{
            label: 'Name',
            value: '{{label}}',
            display: '{{label}}',
            size: 'extend',
            sort: 'label'
        },{
            label: 'Date',
            value: '{{date}}',
            display: '{{date:DD/MM/YYYY H:i:date}}',
            size: 'extend',
            sort: 'date'
        },{
            label: '&nbsp;',
            value: '',
            display: '<a href="#" class="btn btn-light" action="delete-row"><i class="fas fa-trash"></i></a>',
            size: '100'
        }],
        defaultSort: '-date'
    });

    $('#savedSearches').on('shown.bs.modal', (e) => {
        window.savedSearchesTable.resizeTable();
        $('#savedSearches .table-container').removeClass('hidden');
        // setTimeout(() => {
        //     $('#savedSearches .table-container').removeClass('hidden');
        // }, 100);
    });

    $('#savedSearches .btn-save-search').on('click.avolta', (e) => {
        if ($(e.currentTarget).hasClass('disabled'))  {
            return;
        }
        saveSearch();
    })
});