require('typeface-inter');
import '../scss/front.scss';
//import '../scss/admin.scss';

import 'bootstrap/js/dist/util';
// FIXME: gives an error in console but works...
// when using the dist makes dropdown active only once :(
//import 'bootstrap/js/dist/dropdown';

import './components/utils';

import './components/messages';
import './components/front_pages';
import './components/searchbar';
import './components/data_tab';

import './components/revenue_chart';
import './components/ttm_chart';
import './components/activity_chart';

import './components/search_form';
import './components/saved_search';

import './components/startup_form';
import './components/investor_form';
import './components/gmaps';

import './components/document_viewer';
import './components/document_uploader';

import './components/reporting';

// import './components/page';
// import './components/home';
// import './components/startup_chart';
// import './components/deal';
// import './components/deal_chart';
// import './components/startup';
// import './components/pipedrive';
// import './components/edit_startup';
// import './components/advanced_search';
// import './components/multiples';
// import './components/reporting';
//import './components/advanced_search_form';
//import './components/advanced_search_results';
// import './components/searchbar';
// import './components/add_new_deal';
// import './components/investor';
// import './components/advisor';
// import './components/widget_document';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'

// for debugging purpose
window.$ = window.jQuery = require("jquery");
